import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import { baseUrl, getProfile } from "../services/services.profile";
import { message } from "antd";

const PublicOnBoarding = (props) => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({});

  const artPassLink = (profile) => {
    const artPassUrl = `https://${profile.username}.clients.artpass.id`;
    window.open(artPassUrl + "/#/");
  };

  const artPassScanLink = (profile) => {
    const artPassScanUrl = `https://${profile.username}.scan.artpass.id`;
    window.open(artPassScanUrl + "/#/");
  };

  const artPassEidScanLink = (profile) => {
    const artPassScanUrl = `https://${profile.username}.eidscan.artpass.id`;
    window.open(artPassScanUrl + "/#/");
  };

  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSave = async () => {
    axios
      .post(baseUrl+"/api/user/update", {
        username: profile?.username,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
        } else {
          message.success("Updated Successfully");
          setOpen(false);
          getProfile();
        }
      })
      .catch((err) => {
        message.error("Failed to update profile");
      });
  };

  return (
    <>
      <div className="flex justify-between items-center text-[15px] mb-6">
        <button
          className="border border-[#86c1f4] text-primary px-3 h-9  rounded-lg"
          onClick={props.onBack}
        >
          back
        </button>
        <button
          className="bg-primary text-white px-3 h-9 rounded-lg"
          onClick={props.onBack}
        >
          Confirm
        </button>
      </div>
      <div className="bg-[#f0f6fd] px-8 py-4 sm:flex justify-between rounded-md items-center text-[15px] mb-6">
        <div>
          <p className="mb-6 font-semibold">
            Enter your username to create your onboarding links
          </p>
          <div className="bg-white w-full px-6 py-3 mb-6">
            <div className="sm:flex justify-between gap-2 items-center mb-2">
              <p>Username</p>
              <div className="flex justify-start items-center gap-[2px]">
                {open ? (
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="block p-2 h-10 pl-10 w-full text-gray-900 rounded placeholder:text-lg outline-none focus:outline-none border border-[#f2f2f2] text-md"
                    placeholder="Enter username"
                    onChange={(e) => {
                      setProfile({ ...profile, username: e.target.value });
                    }}
                  />
                ) : (
                  <p className="font-bold">@{profile.username}</p>
                )}
                <AiOutlineEdit
                  className="text-primary w-6 h-6 hover:cursor-pointer"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
          <p
            className="underline text-primary text-[15px] hover:cursor-pointer mb-6"
            onClick={() => {
              artPassLink(profile);
            }}
          >
            https://{profile.username}.clients.artpass.id
          </p>
        </div>
        {open ? (
          <button
            className="border border-[#86c1f4] w-full sm:w-auto hover:bg-primary hover:text-white text-primary  h-9 px-3  rounded-lg"
            onClick={() => {
              handleSave();
            }}
          >
            Update
          </button>
        ) : (
          ""
        )}
      </div>
      {/* ----------client ask to comment below code on 12 May 2023---------------- */}
      {/* <div className="bg-[#f0f6fd] px-8 py-4 flex flex-col text-[15px] justify-between rounded-md items-start gap-5  ">
        <p>eID card reader onboarding</p>
        <p
          className="underline  text-primary hover:cursor-pointer"
          onClick={() => {
            artPassScanLink(profile);
          }}
        >
          https://{profile.username}.scan.artpass.id
        </p>
        <p
          className="underline  text-primary hover:cursor-pointer"
          onClick={() => {
            artPassEidScanLink(profile);
          }}
        >
          https://{profile.username}.eidscan.artpass.id
        </p>
      </div> */}
    </>
  );
};

export default PublicOnBoarding;
