import axios from "axios";

// export const baseUrl = "http://192.168.1.3:9099"; //local

let base_url= "https://artpass.id";

if(window.location.hostname=="localhost") {
  // base_url = "http://localhost:9099"; //local

}



export const baseUrl = base_url; // dev or prod

 

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true   //dev or prod
// axios.defaults.withCredentials = false   //local

export const forgotPassword = async (email) => {
  const response = await axios.post(baseUrl + "/api/auth/changePassword", {
    email,
  });
  return response.data;
};
export const login = async (email, password) => {
  const response = await axios.post(baseUrl + "/user/login", {
    email,
    password,
  });

  if (response.data.status === 200) {
    localStorage.setItem("token", response.data.token);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + response.data.token;
  }
  return response.data;
};

export const getProfile = async () => {
  const response = await axios.get(baseUrl + "/api/user/current");
  if (response?.data !== null) {
    localStorage.setItem("userId", response?.data?._id);
  }
  return response.data;
};

