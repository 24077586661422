import React from "react";

const VideoModal = ({ isOpen, setIsOpen }) => {
  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto top-0 ">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setIsOpen(false)}
            ></div>
            <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
              <div className="relative lg:w-[740px] lg:h-[430px] sm:w-[640px] sm:h-[345px] w-[430px] h-[320px] mx-auto bg-white rounded-md shadow-lg ">
                <video
                  controls
                  src="https://artfintech.one/KYC_8_10032022.mp4"
                  className="w-full h-full object-cover absolute"
                  autoplay="{true}"
                  loop
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default VideoModal;
