/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import { baseUrl, getProfile } from "../services/services.profile";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { message } from "antd";
import NotFoundData from "../components/NotFoundData";
import RiskReviewPendingModal from "../components/modal/RiskReviewPendingModal";
import NetworkRiskReviewPendingModal from "../components/modal/NetworkRiskReviewPendingModal";
import FilterModal from "../components/modal/FilterModal";
import { IoMdClose } from "react-icons/io";
import { PDFDocument } from "pdf-lib";

const riskTypeList = [
  { id: 1, name: "PEP", color: "" },
  { id: 2, name: "Adverse Media", color: "" },
  { id: 3, name: "Activity/Funds", color: "" },
  { id: 4, name: "Region", color: "" },
];

const userTypeList = [
  { id: 8, name: "Natural persons" },
  { id: 9, name: "Legal entities" },
];

const ConnectionsRequests = () => {
  const { Card, Empty } = require("antd");
  const [profile, setProfile] = useState({});
  const [networks, setNetworks] = useState([]);
  const [connections, setConnections] = useState([]);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isOpenNetwork, setIsOpenNetwork] = useState(false);
  const [data, setData] = useState({});
  const [connectionDetail, setConnectionDetail] = useState({});
  const [networkDetail, setNetworkDetail] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [connectCountryCode, setConnectCountryCode] = useState("");
  const [search, setSearch] = useState("");
  let [risk, setRisk] = useState([""]);
  let [userType, setUserType] = useState("");
  let [riskRating, setRiskRating] = useState([""]);
  const [filterList, setFilterList] = useState([]);
  let [fill, setFill] = useState("");
  let [isRiskRating, setIsRiskRating] = useState([]);
  let [riskArr, setRiskArr] = useState([]);
  const [toggle, setToggle] = useState(true);
  let [optionVal, setOptionVal] = useState("");
  const [connectionCount, setConnectionCount] = useState(0);
  const [networkCount, setNetworkCount] = useState(0);
  const [isMerging, setIsMerging] = useState(false);
  const [docList, setDocList] = useState([]);

  const viewNetworkCompleteIR = (networkDetail) => {
    setIsMerging(true);

    mergeNetworkPDFs(networkDetail);

    setIsMerging(false);
  };

  const mergeNetworkPDFs = async (networkDetail) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (networkDetail?.user_id?.company) {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company.cert &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.company?.cert;
      PolPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.poa_le &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.company?.poa_le;
    } else {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.cert &&
        "https://artpass.id" + "/" + networkDetail?.user_id?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push("https://artpass.id" + "/" + el?.filename);
      }
    }
    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // Assuming this is the URL to the PDF
      if (networkDetail?.user_id?.company) {
        let arr = [];
        arr = [CddPdf, IRPdf, UboPdf, PolPdf];
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL 
        const response = await fetch(pdfUrl);
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Open the merged PDF in a new window/tab
      const newWindow = window.open(URL.createObjectURL(mergedPdfBlob), '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error("Unable to open a new window for the PDF.");
      }
    } catch (error) {
      console.error("Error merging and displaying PDFs:", error);
    }
  };

  const holdUser = async (connector) => {
    console.log(connector._id);
    const res = await axios
      .post(baseUrl + "/api/connect/hold", {
        _id: connector._id,
        hold: true
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  }

  const unHoldUser = async (connector) => {
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      const res = await axios
        .post(baseUrl + "/api/connect/hold", {
          _id: connector._id,
          hold: false
        })
        .then((res) => {
          if (filterList.length > 0) {
            connectionFilter();
          } else {
            getConnections();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        });
    }
  }

  const unHoldUserNetwork = async (network) => {
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      const res = await axios
        .post(baseUrl + "/api/connect/hold", {
          _id: network.user_id,
          hold: false
        })
        .then((res) => {
          if (filterList.length > 0) {
            networkFilter();
          } else {
            getNetworks();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        });
    }
  }

  const activateUpdate = async (connection) => {
    console.log(connection);
    const res = await axios
      .post(baseUrl + "/api/connect/activate_update", {
        _id: connection.receiver._id
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  }

  const downloadUpdate = async (connection, connector) => {
    // handleMergeClick(connector, connection);
    const res = await axios
      .post(baseUrl + "/api/connect/downloaded_update", {
        conn_id: connection._id,
        user_id: connector._id
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  }

  const handleMergeClick = (user, connection) => {

    // if(user.InspectRp2 && user.InspectRp2.fileUrl) {
      // connector is as user 
      downloadUpdate(connection,user)
    if(user.InspectRp2 && user.InspectRp2.length>0 && Array.isArray(user.InspectRp2) ) {
      window.open("https://artpass.id/" + user.InspectRp2[user.InspectRp2.length-1].fileUrl);
      // console.log("opened file-",user.InspectRp2[user.InspectRp2.length-1].fileUrl)
    } else {
      window.open("https://artpass.id" + "/api/connect/irs/"+connection._id, "_blank");
    }
    // setIsMerging(true);
    // mergePDFs(user, connection);
    // setIsMerging(false);

     


  };

  const mergePDFs = async (userData, connection) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (userData?.company) {
      CddPdf =
        userData?.cdd?.fileP &&
        "https://artpass.id" + "/" + userData?.cdd?.fileP;
      IRPdf =
        userData?.InspectRp?.fileUrl &&
        "https://artpass.id" + "/" + userData?.InspectRp?.fileUrl;
      UboPdf =
        userData?.company &&
        userData?.company.cert &&
        "https://artpass.id" + "/" + userData?.company?.cert;
      PolPdf =
        userData?.company &&
        userData?.company?.poa_le &&
        "https://artpass.id" + "/" + userData?.company?.poa_le;
    } else {
      CddPdf =
        userData?.cdd?.fileP &&
        "https://artpass.id" + "/" + userData?.cdd?.fileP
      IRPdf =
        userData?.InspectRp?.fileUrl &&
        "https://artpass.id" + "/" + userData?.InspectRp?.fileUrl;
      UboPdf =
        userData?.company &&
        userData?.company?.cert &&
        "https://artpass.id" + "/" + userData?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push("https://artpass.id" + "/" + el?.filename);
      }
    }

    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // the URL to the PDF
      if (userData.company) {
        let arr = [];
        // arr = [CddPdf, IRPdf, UboPdf, PolPdf,];
        userData.company.ubo_LE.forEach(le => {
          if(le.uname) {
            arr.push("https://artpass.id" + "/api/connect/irs/"+connection._id);
          }
        });
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL
        let response;
        if(pdfUrl.indexOf(".pdf") >= 0) {
          response = await fetch(pdfUrl);
        } else {
          response = await fetch(pdfUrl, { headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
          } });
        }
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Open the merged PDF in a new window/tab
      const newWindow = window.open(URL.createObjectURL(mergedPdfBlob), '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error("Unable to open a new window for the PDF.");
      }
    } catch (error) {
      console.error("Error merging and displaying PDFs:", error);
    }
  };

  const buttonList = [
    {
      id: 1,
      name: "Low",
      border: "border-[#70ad47]",
      text: "text-[#70ad47]",
      bg: "",
    },
    {
      id: 2,
      name: "Medium",
      border: "border-[#ffc000]",
      text: "text-[#ffc000]",
      bg: "",
    },
    {
      id: 3,
      name: "High",
      border: "border-[#ff0000]",
      text: "text-[#ff0000]",
      bg: "",
    },
  ];

  const countryCodes = [
    { name: "Albania (+355)", code: "355", iso2code: "ALB" },
    { name: "Algeria (+213)", code: "213", iso2code: "DZA" },
    { name: "American Samoa (+684)", code: "684", iso2code: "ASM" },
    { name: "Andorra (+376)", code: "376", iso2code: "AND" },
    { name: "Angola (+244)", code: "244", iso2code: "AGO" },
    { name: "Argentina (+54)", code: "54" },
    { name: "Armenia (+374)", code: "374" },
    { name: "Aruba (+297)", code: "297" },
    { name: "Australia (+61)", code: "61", iso2code: "AUS" },
    { name: "Austria (+43)", code: "43", iso2code: "AUT" },
    { name: "Azerbaijan (+994)", code: "994" },
    { name: "Bangladesh (+880)", code: "+880" },
    { name: "Belarus (+375)", code: "375" },
    { name: "Belgium (+32)", code: "32", iso2code: "BEL" },
    { name: "Bolivia (+591)", code: "591" },
    { name: "Bosnia and Herzegovina (+387)", code: "387" },
    { name: "Botswana (+267)", code: "267" },
    { name: "Brazil (+55)", code: "55" },
    { name: "Bulgaria (+359)", code: "359" },
    { name: "Cambodia (+855)", code: "855" },
    { name: "Cameroon (+237)", code: "237" },
    { name: "Canada (+1)", code: "1", iso2code: "CAN" },
    { name: "Chile (+56)", code: "56" },
    { name: "China (+86)", code: "86" },
    { name: "Colombia (+57)", code: "57" },
    { name: "Costa Rica (+506)", code: "506" },
    { name: "Croatia (+38)", code: "38" },
    { name: "Cuba (+53)", code: "53" },
    { name: "Czech Republic (+420)", code: "420" },
    { name: "Denmark (+45)", code: "45", iso2code: "DNK" },
    { name: "Ecuador (+593)", code: "593" },
    { name: "Egypt (+20)", code: "20" },
    { name: "El Salvador (+503)", code: "503" },
    { name: "Estonia (+372)", code: "372" },
    { name: "Ethiopia (+251)", code: "251" },
    { name: "Finland (+358)", code: "358", iso2code: "FIN" },
    { name: "France (+33)", code: "33", iso2code: "FRA" },
    { name: "Germany (+49)", code: "49", iso2code: "DEU" },
    { name: "Greece (+30)", code: "30" },
    { name: "Hungary (+36)", code: "36" },
    { name: "Iceland (+354)", code: "354", iso2code: "ISL" },
    { name: "India (+91)", code: "91" },
    { name: "Indonesia (+62)", code: "62" },
    { name: "Italy (+39)", code: "39" },
    { name: "Japan (+81)", code: "81" },
    { name: "Mexico (+52)", code: "52" },
    { name: "Netherlands (+31)", code: "31", iso2code: "NLD" },
    { name: "New Zealand (+64)", code: "64", iso2code: "NZL" },
    { name: "Poland (+48)", code: "48" },
    { name: "Romania (+40)", code: "40" },
    { name: "Switzerland (+41)", code: "41" },
    { name: "United Kingdom (+44)", code: "44", iso2code: "GBR" },
    { name: "United States of America (+1)", code: "1", iso2code: "USA" },
    { name: "Venezuela (+58)", code: "58" },
    { name: "Vietnam (+84)", code: "84", iso2code: "VNM" },
  ];

  const getConnections = async () => {
    let arr = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    let url = baseUrl + "/api/connections?search=" + search;
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      url = baseUrl + "/api/all_connections?search=" + search
    }
    await axios
      .get(url)
      .then((res) => {
        // arr = res.data.data;
        arr = res?.data?.data.sort(function (a, b) {
          return Math.floor(b.connection_time) - Math.floor(a.connection_time);
        });
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
                ? "Medium"
                : yellowCount > 1 && redCount === 0
                  ? "High"
                  : redCount > 0
                    ? "High"
                    : "";
        }
        if (res.data.users) { setUsers(res.data.users); }
        setRisk("");
        setUserType("");
        setFill("");
        setRiskRating([]);
        // setIsRiskRating([]);
        setFilterList([]);
        setConnections(arr);
      });
  };

  const connectInvisibly = async (user) => {
    await axios
      .post(baseUrl + "/api/connect/connect_inv", {
        connect_to: user._id,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          return;
        }
        message.success("Connected Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
        message.error("Request failed");
      });
  }

  const getNetworks = () => {
    axios.get(baseUrl + "/api/user/networks?search=" + search).then((res) => {
      let unique = [];
      let distinct = [];
      let greenCount = 0;
      let yellowCount = 0;
      let redCount = 0;

      res?.data?.data?.forEach((element) => {
        if (!unique[element.user_id?._id]) {
          distinct.push(element);
          unique[element?.user_id?._id] = 1;
        }
      });

      distinct = distinct.sort(function (a, b) {
        return a.created_time > b.created_time
          ? -1
          : a.created_time < b.created_time
            ? 1
            : 0;
      });
      for (let i = 0; i < distinct.length; i++) {
        const item = distinct[i];
        for (let i = 0; i < item?.risk_rating_list.length; i++) {
          const el = item?.risk_rating_list[i];
          if (el.color === "bg-[#70ad47]") {
            greenCount++;
          }
          if (el.color === "bg-[#ffc000]") {
            yellowCount++;
          }
          if (el.color === "bg-[#ff0000]") {
            redCount++;
          }
        }
        item.overrallRisk =
          greenCount > 0 && yellowCount === 0 && redCount === 0
            ? "Low"
            : yellowCount === 1 && redCount === 0
              ? "Medium"
              : yellowCount > 1 && redCount === 0
                ? "High"
                : redCount > 0
                  ? "High"
                  : "";
      }
      setRisk("");
      setUserType("");
      setFill("");
      setRiskRating([]);
      setFilterList([]);
      setNetworks(distinct);
    });
  };

  // connection api
  const removeConnection = (connection) => {
    axios
      .delete(baseUrl + "/api/connect/" + connection?.req_id)
      .then((res) => {
        message.info("Connection Removed Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineReqCddAccess = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection?.req_id + "/decline-access-cdd"
      )
      .then((res) => {
        message.info("Coonection Declined Successfully");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewArtpassID = (user) => {
    window.open(baseUrl + "/connectreport/" + user?._id, "_blank");
  };

  const viewCDDReport = (user) => {
    window.open(baseUrl + "/" + user?.cdd?.fileP, "_blank");
  };

  const viewInspectionReport = (user) => {
    window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
  };

  const viewRequestedCDDReport = (connection, token) => {
        window.open(
      baseUrl + "/api/connect/" + connection?.req_id + "/view/" + token,
      "_blank"
    );
  };

  const viewRequestedInspectionReport = (user) => {
    window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
    // window.open(baseUrl + "/api/sharedIR/" + user.access_token, "_blank");
  };

  const requestCDDReport = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/request-access-cdd"
      )
      .then((res) => {
        message.info("CDD Requested Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/accept-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Accepted Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/decline-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Declined");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const revokeCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/revoke-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Accepted Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // network api
  const viewArtpassNetworkID = (network) => {
    window.open(baseUrl + "/connectreport/" + network?.user_id?._id, "_blank");
  };

  const networkCDDReport = (network) => {
    axios
      .post(baseUrl + "/api/user/request-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Requested Successfully");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ampRequestCddReport = (network) => {
    axios
      .post(baseUrl + "/api/user/amp-rq-access-cdd", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Requested Successfully");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const increaseusage = (conn, user) => {
    if (profile?.subscription != undefined) {
      let val = {
        rec_id: conn.receiver._id,
        req_id: conn.req_id,
      };
      axios
        .post(baseUrl + "/api/decreaseCountforR/ir", {
          val,
        })
        .then((res) => {
          if (res.data?.error) {
            if (res.data.error.message != "Already Viewed") {
              message.error(res.data?.error.message);
            } else {
              window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
              getConnections();
            }
          } else {
            window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
            getConnections();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error("You don't have any subscription plan");
    }
  };

  const appAcceptShare = (network) => {
    axios
      .post(baseUrl + "/api/user/app-accept-share-cdd", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Accepted Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptRqCddReport = (network) => {
    axios
      .post(baseUrl + "/api/user/accept-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Accepted Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewNetworkCDDReport = (network) => {
    window.open(baseUrl + "/" + network?.user_id?.cdd?.fileP, "_blank");
  };

  const viewNetworkInspectionReport = (network) => {
    // window.open(baseUrl + "/" + network?.user_id?.InspectRp?.fileUrl, "_blank");
    // window.open(baseUrl + "/api/network/ir?nid=" + network?.network_id +"&token="+network?.secret_token , "_blank");
    // window.open(baseUrl + "/api/network/ir?nid=" + network?.network_id  , "_blank");
    if (profile?.subscription != undefined) {
      let val = {
        network_id: network?.network_id,
        // req_id:conn.req_id
      };
      axios
        .post(baseUrl + "/api/decreaseCountforNetwork/irnet", {
          val,
        })
        .then((res) => {
          if (res.data?.error) {
            if (res.data.error.message != "Already Viewed") {
              message.error(res.data?.error.message);
            } else {
              window.open(baseUrl + "/" + res.data.message, "_blank");
              getNetworks();
            }
          } else {
            window.open(baseUrl + "/" + res.data.message, "_blank");
            getNetworks();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error("You don't have any subscription plan");
    }
  };

  const revokeAccessCddAndIRFromApplicant = (network) => {
    axios
      .post(baseUrl + "/api/user/revoke-amp-access", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Remove Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAccessCddAndIRFromAMP = (network) => {
    axios
      .post(baseUrl + "/api/user/remove-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Remove Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteNetwork = (network) => {
    axios
      .post(baseUrl + "/api/user/delete-cdd-network", network)
      .then((res) => {
        message.info("Network Removed Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleButton = (connector, connection) => {
    if (toggle === true) {
      setIsOpen(true);
      setData(connector);
      setConnectionDetail(connection);
    } else {
      setIsOpen(false);
    }
  };

  const handleNetworkButton = (network) => {
    if (toggle === true) {
      setIsOpenNetwork(true);
      setNetworkDetail(network);
    } else {
      setIsOpenNetwork(false);
    }
  };

  const addCountryCode = (network) => {
    for (let i = 0; i < countryCodes.length; i++) {
      const el = countryCodes[i];
      const countryCode = el?.iso2code;
      const connectionCountryCode =
        network?.user_id && network?.user_id?.amlReport?.country;
      if (countryCode === connectionCountryCode) {
        setCountryCode(el?.code);
      }
    }
  };

  const addConnectionCountryCode = (connector) => {
    for (let i = 0; i < countryCodes.length; i++) {
      const el = countryCodes[i];
      const countryCode = el?.iso2code;
      const connectionCountryCode = connector?.location?.country;
      if (countryCode === connectionCountryCode) {
        setConnectCountryCode(el?.code);
      }
    }
  };

  const handleFilter = () => {
    setIsRiskRating(riskTypeList);
    setRisk([]);
    setRiskArr(buttonList);
    setUserType("");
    setFill("");
    setRiskRating([]);
    setFilterList([]);
    setFilterOpen(true);
  };

  const handleClearFilter = async () => {
    setRisk("");
    setUserType("");
    setFill("");
    setRiskRating([]);
    setFilterList([]);
    getConnections();
    getNetworks();
  };

  const removeRisk = async (item) => {
    if (risk.includes(item)) {
      risk = risk.filter((el) => el !== item);
      setRisk(risk);
      connectionFilter();
      networkFilter();
    }
  };

  const handleRiskRating = async (item) => {
    if (riskRating.includes(item)) {
      riskRating = riskRating.filter((el) => el !== item);
      setRiskRating(riskRating);
      connectionFilter();
      networkFilter();
    }
  };

  const handleUserType = async (item) => {
    userType = "";
    setUserType(userType);
    connectionFilter();
    networkFilter();
  };

  const connectionFilter = async () => {
    let arr = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;

    const res = await axios
      .post(baseUrl + "/api/connect/filter", {
        risk: risk,
        user_type: userType,
        risk_type: riskRating,
      })
      .then((res) => {
        // arr = res?.data?.data;
        arr = res?.data?.data.sort(function (a, b) {
          return Math.floor(b.connection_time) - Math.floor(a.connection_time);
        });
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
                ? "Medium"
                : yellowCount > 1 && redCount === 0
                  ? "High"
                  : redCount > 0
                    ? "High"
                    : "";
        }

        setFilterOpen(false);
        setFill("");
        setIsRiskRating([]);
        setConnections(arr);
        if (risk.length !== 0 || userType !== "" || riskRating.length !== 0) {
          setConnectionCount(arr.length);
        }
        if (risk.length === 0 && userType === "" && riskRating.length === 0) {
          setFilterList([]);
        } else {
          setFilterList(arr);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
      });
  };

  const networkFilter = async () => {
    let unique = [];
    let distinct = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;

    const res = await axios
      .post(baseUrl + "/api/network/filter", {
        risk: risk,
        user_type: userType,
        risk_type: riskRating,
      })
      .then((res) => {
        res?.data?.forEach((element) => {
          if (!unique[element.user_id?._id]) {
            distinct.push(element);
            unique[element?.user_id?._id] = 1;
          }
        });

        distinct = distinct.sort(function (a, b) {
          return a.created_time > b.created_time
            ? -1
            : a.created_time < b.created_time
              ? 1
              : 0;
        });
        for (let i = 0; i < distinct.length; i++) {
          const item = distinct[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
                ? "Medium"
                : yellowCount > 1 && redCount === 0
                  ? "High"
                  : redCount > 0
                    ? "High"
                    : "";
        }

        setFilterOpen(false);
        setFill("");
        setIsRiskRating([]);
        setNetworks(distinct);
        if (risk.length !== 0 || userType !== "" || riskRating.length !== 0) {
          setNetworkCount(distinct.length);
        }
        if (risk.length === 0 && userType === "" && riskRating.length === 0) {
          setFilterList([]);
        } else {
          setFilterList(distinct);
        }
      })
      .catch((err) => {
        console.log(err, "errerererere");
        message.error("Something went wrong!!");
      });
  };

  const handleSubmit = async () => {
    connectionFilter();
    networkFilter();
  };

  const handleSearch = () => {
    getConnections(search);
    getNetworks(search);
  };

  const handleCopilot = async () => {
    // const res = await axios
    //   .post(baseUrl + "/api/user/updateCopilot", {
    //     userId: profile?._id,
    //     copilot: toggle,
    //   })
    //   .then((res) => {
    //     getProfile();
    //   })
    //   .catch((error) => {
    //     message.error(res?.data?.error);
    //   });
  };

  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
        // setToggle(res?.copilot);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    handleCopilot();
  }, [toggle]);

  useEffect(() => {
    if (filterList.length > 0) {
      connectionFilter();
    } else {
      getConnections();
    }
  }, [connectionDetail]);

  useEffect(() => {
    if (filterList.length > 0) {
      networkFilter();
    } else {
      getNetworks();
    }
  }, [networkDetail]);

  return (
    <>
      <FilterModal
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        risk={risk}
        setRisk={setRisk}
        userType={userType}
        setUserType={setUserType}
        riskRating={riskRating}
        setRiskRating={setRiskRating}
        handleSubmit={handleSubmit}
        fill={fill}
        setFill={setFill}
        isRiskRating={isRiskRating}
        setIsRiskRating={setIsRiskRating}
        riskArr={riskArr}
        setRiskArr={setRiskArr}
      />

      <RiskReviewPendingModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
        connectionDetail={connectionDetail}
        getConnections={getConnections}
        connections={connections}
        connectCountryCode={connectCountryCode}
        connectionFilter={connectionFilter}
        networkFilter={networkFilter}
        filterList={filterList}
        optionVal={optionVal}
      />

      <NetworkRiskReviewPendingModal
        isOpen={isOpenNetwork}
        setIsOpen={setIsOpenNetwork}
        networkDetail={networkDetail}
        getNetworks={getNetworks}
        networks={networks}
        countryCode={countryCode}
        connectionFilter={connectionFilter}
        networkFilter={networkFilter}
        filterList={filterList}
        optionVal={optionVal}
      />

      <div
        wrap="true"
        className="text-right flex justify-between sm:flex-row flex-col gap-y-3 sm:gap-y-0"
      >
        <div className="flex justify-start items-center gap-3">
          <input
            type="text"
            className="w-auto md:w-56 h-8 rounded-md border border-[#d9d9d9] !outline-none focus:!outline-none focus:border-[#d9d9d9]"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            type="button"
            className="text-xs border-primary border font-semibold tracking-wide text-primary w-28 px-2 h-8 rounded-lg"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        <div className="flex justify-start sm:justify-end items-center gap-10">
          

          {toggle === true && (
            <>
              <div className="w-36 flex justify-end sm:justify-start items-center">
                {filterList.length === 0 &&
                  risk.length === 0 &&
                  userType === "" &&
                  riskRating.length === 0 ? (
                  <button
                    type="button"
                    className="text-xs border-primary border font-semibold tracking-wide text-primary w-28 px-2 h-8 rounded-lg"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                ) : (
                  <button
                    type="button"
                    className="text-xs border-primary border font-semibold tracking-wide text-primary w-36 px-2 h-8 rounded-lg"
                    onClick={handleClearFilter}
                  >
                    Clear Filter selection
                  </button>
                )}
              </div>
            </>
          )}
          {/* </div> */}

          {/* <Select
            style={{
              width: 180,
              textAlign: "center",
            }}
            placeholder={"Sort: "}
            onChange={handleChange}
            options={[
              // {
              //   value: "Aplhabatically",
              //   label: "Aplhabatically",
              // },
              {
                value: "Old - New",
                label: "Old - New",
              },
              {
                value: "New - Old",
                label: "New - Old",
              },
              // {
              //   value: "Private Person Only",
              //   label: "Private Person Only",
              // },
              // {
              //   value: "Legal Entities Only",
              //   label: "Legal Entities Only",
              // },
            ]}
          /> */}
        </div>
      </div>

      {connectionCount + networkCount !== 0 &&
        (risk.length > 0 || riskRating.length > 0 || !!userType) && (
          <div className="flex gap-5 py-5">
            <div>
              {(risk.length > 0 || riskRating.length > 0 || !!userType) && (
                <p className="w-36 pl-5 font-semibold whitespace-nowrap pr-5">
                  Results: {connectionCount + networkCount}{" "}
                </p>
              )}
            </div>
            <div className="flex justify-start gap-4 pb-2 px-2 w-full break-all flex-wrap">
              {risk.length > 0 && (
                <>
                  {buttonList.map((item) => (
                    <>
                      {risk.includes(item.name) && (
                        <button
                          type="button"
                          className="fs-13 w-40 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                        >
                          {item?.name} Risk
                          <IoMdClose
                            size={15}
                            onClick={() => removeRisk(item.name)}
                          />
                        </button>
                      )}
                    </>
                  ))}
                </>
              )}

              {riskRating.length > 0 && (
                <>
                  {riskTypeList.map((item) => (
                    <>
                      {riskRating.includes(item.name) && (
                        <button
                          type="button"
                          className="fs-13 w-40 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                        >
                          {item?.name}
                          <IoMdClose
                            size={15}
                            onClick={() => handleRiskRating(item.name)}
                          />
                        </button>
                      )}
                    </>
                  ))}
                </>
              )}

              {!!userType && (
                <>
                  {userTypeList.map((item) => (
                    <>
                      {userType === item?.name && (
                        <button
                          type="button"
                          className="fs-13 w-40 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                        >
                          {item?.name}
                          <IoMdClose
                            size={15}
                            onClick={() => handleUserType(item.name)}
                          />
                        </button>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      {connections?.length === 0 && networks?.length === 0 ? (
        <>
          <NotFoundData
            message={
              <>
                Requests to make connections with you will appear here <br /> to
                access your artpass ID and CDD Report
              </>
            }
          />
        </>
      ) : (
        <>
          {connections.length !== 0 && (
            <p className="font-bold text-lg pb-5 pt-3 flex justify-center items-center w-full tracking-wide">
              Scan & Connect Connections
            </p>
          )}
          {(connections || []).map((connection, i) => {
            // const connector =
            //   profile?._id == connection?.sender._id
            //     ? connection?.receiver
            //     : connection?.sender;
            const connector =
              profile?._id == connection?.sender._id
                ? {
                  ...{
                    ...connection?.receiver,
                    ...connection?.sender_req_cdd,
                  },
                }
                : {
                  ...{
                    ...connection?.sender,
                    ...connection?.receiver_req_cdd,
                  },
                };
            return (
              <div className="sm:flex  w-full gap-4 mb-6" key={i}>
                <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center  mb-4 md:mb-0 " style={{ flexDirection: "column"}}>
                  <img
                    className="h-[98px] w-[120px] object-fill"
                    // src={"https://artpass.id" + "/" + connector?.avatar}
                    src={baseUrl + "/" + connector?.avatar}
                    alt=""
                  />
                </div>

                <div className="2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 tab_design">
                  <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                    <div className="mb-4 text-[15px] w-full sm:w-auto">
                      {/* connector company name  */}
                      <p className="font-semibold">
                        {connector?.company?.name}
                      </p>

                      {/* connector full name  */}
                      <p
                        onClick={() => handleButton(connector, connection)}
                        className={`font-bold ${toggle === true &&
                          ((profile?._id == connection?.receiver?._id &&
                            connection?.receiver_req_cdd &&
                            connection?.receiver_req_cdd?.status ==
                            "accepted") ||
                            (profile?._id == connection?.sender?._id &&
                              connection?.sender_req_cdd &&
                              connection?.sender_req_cdd?.status ==
                              "accepted")) &&
                          "text-primary underline cursor-pointer"
                          }`}
                      >
                        {connector?.fname + " " + connector?.lname}
                      </p>

                      {/* connector username  */}
                      <p className="font-semibold">{connector?.username} </p>
                    </div>

                    <div className="flex flex-col gap-2 ms:mb-3 md:mb-0  w-full sm:w-auto cstRedBtn">
                      {/* Decline button  */}
                      {/* {(profile?._id == connection?.sender?._id &&
                        connection?.receiver_req_cdd?.status == "pending") ||
                        (profile?._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <button
                              className="px-2 h-9 sm:h-7 border border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                              onClick={() => {
                                declineReqCddAccess(connection);
                              }}
                            >
                              Decline to share your CDD
                            </button>
                          ))} */}

                      {/* Remove Connection button  */}
                      <button
                        className="fs-13 px-2 h-9 sm:h-7 border whitespace-nowrap border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger  hover:text-white hover:bg-danger"
                        onClick={() => {
                          removeConnection(connection);
                        }}
                      >
                        Remove connection
                      </button>

                      {/* Remove Access to my CDD button  */}
                      {((profile?._id == connection?.sender._id &&
                        connection?.receiver_req_cdd &&
                        Object.keys(connection?.receiver_req_cdd).length > 0 &&
                        connection?.receiver_req_cdd?.status == "accepted") ||
                        (profile._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd &&
                          Object.keys(connection?.sender_req_cdd).length > 0 &&
                          connection?.sender_req_cdd?.status ==
                          "accepted")) && (
                          <>
                            <button
                              className="fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px] text-danger hover:text-white hover:bg-danger"
                              onClick={() => {
                                revokeCDDRequest(connection);
                              }}
                            >
                              Remove access to my CDD
                            </button>
                          </>
                        )}
                    </div>
                  </div>

                  <div
                    className={`grid grid-cols-1 ${toggle === true && "xl:grid-cols-2"
                      } text-[14px] lg:text-[15px] w-full justify-center xl:justify-end items-start gap-2 xl:gap-3`}
                  >
                    {toggle === true && (
                      <div className="flex flex-col xl:justify-end justify-start ms:justify-end items-start ms:items-end xl:items-start">
                        {/* Risk review pending  */}
                        {((profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "accepted") ||
                          (profile?._id == connection?.sender?._id &&
                            connection?.sender_req_cdd &&
                            connection?.sender_req_cdd?.status ==
                            "accepted")) && (
                            <>
                              {connection &&
                                connection?.comment_list?.length === 0 &&
                                connection?.risk_status === "" ? (
                                <>
                                  <button
                                    className="fs-13 bg-primary text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                    onClick={() => {
                                      addConnectionCountryCode(connector);
                                      handleButton(connector, connection);
                                    }}
                                  >
                                    {/* main btn */}
                                    Risk Review Pending
                                  </button>
                                  <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                    CDD Report Expires on:{" "}
                                    {/* {connection?.sender?.cdd?.validTill} */}
                                    {profile?._id === connection?.sender?._id ?
                                      connection?.receiver?.cdd?.validTill
                                      : connection?.sender?.cdd?.validTill}
                                  </p>
                                  <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                    ID Expiration Date:{" "}
                                    {console.log(connection?.receiver?.amlReport)}
                                    {
                                      profile?._id === connection?.sender?._id ?
                                          moment(connection?.receiver?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil).format("DD MMM YYYY")
                                          :
                                          moment(connection?.sender?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil).format("DD MMM YYYY")
                                    }
                                  </p>
                                </>
                              ) : (
                                <>
                                  {connection?.overrall_Risk === "Low" && (
                                    <button
                                      className={`fs-13 text-[#70ad47] border-[#70ad47] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                      onClick={() => {
                                        addConnectionCountryCode(connector);
                                        handleButton(connector, connection);
                                      }}
                                    >
                                      Low Risk
                                    </button>
                                  )}

                                  {connection?.overrall_Risk === "Medium" && (
                                    <button
                                      className={`fs-13 text-[#ffc000] border-[#ffc000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                      onClick={() => {
                                        addConnectionCountryCode(connector);
                                        handleButton(connector, connection);
                                      }}
                                    >
                                      Medium Risk
                                    </button>
                                  )}

                                  {connection?.overrall_Risk === "High" && (
                                    <button
                                      className={`fs-13 text-[#ff0000] border-[#ff0000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                      onClick={() => {
                                        addConnectionCountryCode(connector);
                                        handleButton(connector, connection);
                                      }}
                                    >
                                      High Risk
                                    </button>
                                  )}

                                  {connection?.risk_status !== "" && (
                                    <>
                                      <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                        Last assessed:{" "}
                                        {moment
                                          .unix(
                                            connection?.updated_risk_time / 1000
                                          )
                                          .format("DD MMM YYYY")}{" "}
                                      </p>
                                      <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                        CDD Report Expires on:{" "}
                                        {/* {connection?.sender?.cdd?.validTill} */}
                                        {profile?._id === connection?.sender?._id ?
                                          connection?.receiver?.cdd?.validTill
                                          : connection?.sender?.cdd?.validTill}
                                      </p>
                                      <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                        ID Expiration Date:{" "}
                                        {
                                          profile?._id === connection?.sender?._id ?
                                              moment(connection?.receiver?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil).format("DD MMM YYYY")
                                              :
                                              moment(connection?.sender?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil).format("DD MMM YYYY")
                                        }
                                      </p>
                                    </>

                                  )}
                                </>
                              )}
                              { !connector.hold && profile._id == "652943c69df2a7301b23c9ab" && <button className="fs-13 text-primary border-[#007aea] mt-5 border-2 px-2 h-9 rounded-lg !w-36 riskbtn" onClick={() => {holdUser(connector);}} >Activate On Hold</button>}
                              { connector.hold && <button onClick={() => {unHoldUser(connector);}} className="fs-13 bg-danger text-white px-2 mt-5 h-9 !w-36 rounded-lg">On Hold</button>}

                            </>
                          )}
                      </div>
                    )}

                    <div className="flex flex-col md:flex-col xl:flex-row xl:justify-end justify-start ms:justify-end items-start ms:items-end xl:items-center gap-2 xl:gap-3">
                      <div className="flex flex-col order-2 md:order-2 xl:order-1 gap-3 justify-center items-start ms:items-end mob:items-center cstBtn">
                        {/* Share your CDD Report button  */}
                        {/* ACCEPT FOR SENDER */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <>
                              <button
                                className="fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                                onClick={() => {
                                  acceptCDDRequest(connection);
                                }}
                              >
                                Accept to share your CDD Report
                              </button>
                            </>
                          )}
                        {/* ACCEPT FOR RECEIVER */}
                        {profile._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <>
                              <button
                                className="fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                                onClick={() => {
                                  acceptCDDRequest(connection);
                                }}
                              >
                                Accept to share your CDD Report
                              </button>
                            </>
                          )}
                        {/* decline FOR SENDER */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <>
                              <button
                                className="fs-13 border break-words px-2 h-9 rounded-lg border-danger text-danger"
                                onClick={() => {
                                  declineCDDRequest(connection);
                                }}
                              >
                                Decline to share your CDD Report
                              </button>
                            </>
                          )}
                        {/* decline FOR RECEIVER */}
                        {profile._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <>
                              <button
                                className="fs-13 border break-words px-2  h-9 rounded-lg border-danger text-danger"
                                onClick={() => {
                                  declineCDDRequest(connection);
                                }}
                              >
                                Decline to share your CDD Report
                              </button>
                            </>
                          )}
                        {/* Decline to share your CDD button  */}
                        {/* {(profile?._id == connection?.sender?._id &&
                      connection?.receiver_req_cdd &&
                      connection?.receiver_req_cdd?.status == "pending") ||
                      (profile?._id == connection?.receiver?._id &&
                        connection?.sender_req_cdd &&
                        connection?.sender_req_cdd?.status == "pending" && (
                          <>
                            <button
                              className="border border-[#86c1f4] whitespace-nowrap text-primary w-full xl:px-2  h-9  rounded-lg"
                              onClick={() => {
                                declineCDDRequest(connection);
                              }}
                            >
                              Decline to share your CDD
                            </button>
                          </>
                        ))} */}

                        {/* CDD request pending button  */}
                        {profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <button className="fs-13 bg-gray-300 hover:cursor-not-allowed text-primary px-2 h-9 rounded-lg">
                              CDD Request Pending
                            </button>
                          )}
                        {profile?._id == connection?.sender?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <button className="fs-13 bg-gray-300 hover:cursor-not-allowed text-primary px-2  h-9  rounded-lg">
                              CDD Request Pending
                            </button>
                          )}
                      </div>

                      <div className="flex flex-col order-1 md:order-1 xl:order-2 justify-center items-start ms:items-end gap-3 mob:items-center cstBtnWidth">
                        {/* artpass ID button  */}
                        <button
                          className="fs-13 border border-[#86c1f4] text-primary px-2 h-9 rounded-lg"
                          onClick={() => {
                            viewArtpassID(connector);
                          }}
                        >
                          artpass ID
                        </button>
                        {/* Request CDD Report for sender */}
                        {profile._id === connection.sender._id &&
                          !connection.sender_req_cdd &&
                          !connection.receiver.is_sharing_cdd && (
                            <button
                              className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                              onClick={() => {
                                requestCDDReport(connection);
                              }}
                            >
                              Request CDD Report
                            </button>
                          )}
                        {/* <button
                        className="border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                        onClick={() => {
                          increaseusage(connection);
                        }}
                      >
incre                      </button> */}
                        {/* Request CDD Report for reciever */}

                        {profile._id === connection.receiver._id &&
                          !connection.receiver_req_cdd &&
                          !connection.sender.is_sharing_cdd && (
                            <button
                              className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                              onClick={() => {
                                requestCDDReport(connection);
                              }}
                            >
                              Request CDD Report
                            </button>
                          )}
                        {/* <div className="fixedwidthbtnWrap" ng-show="(currentUser._id === conn.sender._id) && !conn.sender_req_cdd && !conn.receiver.is_sharing_cdd">
                                    <input
                                        id="btn-request-cf-cdd-{{$index}}"
                                        type="button"
                                        value="Request CDD Report"
                                        style="width: 100%;"
                                        className="butn butn-outline-primary"
                                        ng-click="requestCDDrp(conn.req_id, $index)"
                                    />
                                </div> */}
                        {/* {connector?.is_sharing_cdd && (   cdd only */}
                        {connector?.is_sharing_cdd &&
                          connection?.sender_req_cdd?.status != "pending" &&
                          connection?.sender_req_cdd?.status != "accepted" && ( // cdd to req check
                            <>
                              {/* <br /> */}
                              <button
                                className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                                // onClick={() => {
                                //   viewCDDReport(connector);
                                // }}
                                onClick={() => {
                                  requestCDDReport(connection);
                                }}
                              >
                                {/* CDD Report */}
                                Request CDD Report
                              </button>
                              {/* <button
                            className="bg-primary w-full  text-white px-2  h-9  rounded-lg"
                            onClick={() => {
                              viewInspectionReport(connector);
                            }}
                          >
                            Inspection Report
                          </button> */}
                            </>
                          )}

                        {/* CDD Report &   Inspection Report button for sender  */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "accepted" && (
                            <>
                              <button
                                className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg
                            "
                                onClick={() => {
                                  viewRequestedCDDReport(
                                    connection,
                                    connection?.sender_req_cdd?.access_token
                                  );
                                }}
                              >
                                CDD Report
                              </button>
                              {/* <button
                                className="fs-13 bg-primary text-white px-2  h-9  rounded-lg"
                                onClick={() => {
                                  viewRequestedInspectionReport(connector);
                                }}
                              >
                                Inspection Report
                              </button> */}
                              {connector?.update_available? <button
                                className="fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                onClick={() => handleMergeClick(connector, connection)}
                              >
                                Inspection Report
                              </button>
                              : 
                              <button
                                className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                onClick={() => handleMergeClick(connector, connection)}
                              >
                                Inspection Report
                              </button>}

                             
                              {/* {console.log(connection)} */}

                            </>
                          )}

                        {/* CDD Report &   Inspection Report button for receiver */}
                        {profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status ==
                          "accepted" && (
                            <>
                              <button
                                className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                                onClick={() => {
                                  viewRequestedCDDReport(
                                    connection,
                                    connection?.receiver_req_cdd?.access_token
                                  );
                                }}
                              >
                                CDD Report
                              </button>
                              {/* <button
                                className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                onClick={() => {
                                  // viewRequestedInspectionReport(connector);
                                  increaseusage(connection, connector);
                                }}
                              >
                                Inspection Report
                              </button> */}
                              {/* <button
                                className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                onClick={() => handleMergeClick(connector, connection)}
                              >
                                Inspection Report
                              </button> */}
                             {connector?.update_available? <button
                                className="fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                onClick={() => handleMergeClick(connector, connection)}
                              >
                                Inspection Report
                              </button>
                              : 
                              <button
                                className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                onClick={() => handleMergeClick(connector, connection)}
                              >
                                Inspection Report
                              </button>}



                            </>
                          )}
                        {/* Request CDD Report button  */}
                        {/* {!connector.is_sharing_cdd &&
                        !(
                          profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd
                        ) && (
                          <button
                            className="border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                            onClick={() => {
                              requestCDDReport(connection);
                            }}
                          >
                            Request CDD Report
                          </button>
                        )} */}
                        {/* { profile._id == "652943c69df2a7301b23c9ab" && <button className="fs-13 border border-[#ffc100] text-[#ffc100] px-2  h-9 rounded-lg" onClick={() => {activateUpdate(connection);}} >Activate Update</button>} */}
                        {/* { profile._id != "652943c69df2a7301b23c9ab" && connector.update_available && ((profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "accepted") || (profile?._id == connection?.sender?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "accepted")) && <button onClick={() => {downloadUpdate(connection, connector);}} className="fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg">Download Update</button>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {networks.length !== 0 && (
            <p className="font-bold text-lg pt-2 pb-7 flex justify-center items-center w-full tracking-wide">
              Onboarding Connections
            </p>
          )}
          {(networks || []).map((network, i) => {
            return (
              <div key={i}>
                {network?.user_id !== null && (
                  <div className="sm:flex w-full gap-4 mb-6">
                    <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center mb-4 md:mb-0">
                      <img
                        className="h-[98px] w-[120px] object-fill"
                        // src={"https://artpass.id" + "/" +  network?.user_id?.avatar }
                        src={baseUrl + "/" + network?.user_id?.avatar}
                        alt=""
                      />
                    </div>

                    <div className="2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 grid grid-cols-1 sm:grid-cols-2 xl:gap-3 tab_design">
                      <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                        <div className="mb-4 text-[15px] w-full sm:w-auto">
                          {/* network user company name  */}
                          <p className="font-semibold">
                            {/* {network?.company?.name} */}
                            {network?.user_id?.company?.name}
                          </p>

                          {/* network user full name  */}
                          <p
                            onClick={() => handleNetworkButton(network)}
                            className={`font-bold ${toggle === true &&
                              ((network?.is_amp == true &&
                                network?.amp_request_cdd?.status ==
                                "accepted") ||
                                (network?.is_applicant == true &&
                                  network?.amp_request_cdd?.status ==
                                  "accepted")) &&
                              "text-primary underline cursor-pointer"
                              }`}
                          >
                            {network?.user_id?.fname +
                              " " +
                              network?.user_id?.lname}
                          </p>

                          {/* network user username  */}
                          <p className="font-semibold">
                            {network?.user_id?.username}
                          </p>
                        </div>

                        <div className="flex flex-col gap-2 ms:mb-3 md:mb-0  w-full sm:w-auto cstRedBtn">
                          {/* Decline button  */}
                          {/* <button
                            className="px-2 h-9 sm:h-7 border border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                            onClick={() => {
                              declineReqCddAccess(network);
                            }}
                          >
                            Decline to share your CDD
                          </button> */}
                          {/* ))} */}

                          {/* Remove Connection button  */}
                          <button
                            className="fs-13 px-2 h-9 sm:h-7 border whitespace-nowrap border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger  hover:text-white hover:bg-danger"
                            onClick={() => {
                              deleteNetwork(network);
                            }}
                          >
                            Remove connection
                          </button>

                          {/* Remove Access to my CDD button  */}
                          {network?.is_amp == true &&
                            network?.amp_request_cdd?.status == "accepted" && (
                              <>
                                <button
                                  className="fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px]  text-danger hover:text-white hover:bg-danger"
                                  onClick={() => {
                                    revokeAccessCddAndIRFromApplicant(network);
                                  }}
                                >
                                  Remove access to my CDD
                                </button>
                              </>
                            )}

                          {/* Remove Access to my CDD button  */}
                          {network?.is_applicant == true &&
                            network?.app_request_cdd?.status == "accepted" && (
                              <>
                                <button
                                  className="fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px] text-danger hover:text-white hover:bg-danger"
                                  onClick={() => {
                                    removeAccessCddAndIRFromAMP(network);
                                  }}
                                >
                                  Remove Access to my CDD
                                </button>
                              </>
                            )}
                        </div>
                      </div>

                      <div
                        className={`grid grid-cols-1  ${toggle === true && "xl:grid-cols-2"
                          } text-[14px] lg:text-[15px] w-full justify-center items-start gap-2 xl:gap-3`}
                      >
                        {toggle === true && (
                          <div className="flex flex-col xl:justify-center justify-start ms:justify-end items-start ms:items-end xl:items-start">
                            {/* Risk review pending  */}
                            {(network?.is_amp == true &&
                              network?.amp_request_cdd?.status == "accepted") ||
                              (network?.is_applicant == true &&
                                network?.amp_request_cdd?.status ==
                                "accepted" && (
                                  <>
                                    {network?.comment_list.length === 0 ||
                                      network?.risk_status === "" ? (
                                      <>
                                        <button
                                          className="fs-13 bg-primary text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                          onClick={() => {
                                            addCountryCode(network);
                                            handleNetworkButton(network);
                                          }}
                                        >
                                          Risk Review Pending
                                        </button>


                                        <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                          CDD Report Expires on:{" "}
                                          {network?.user_id?.cdd?.validTill}
                                        </p>
                                        <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                          ID Expiration Date:{" "}
                                          {moment(
                                            network?.user_id?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil
                                          ).format("DD MMM YYYY")}
                                        </p>
                                      </>

                                    ) : (
                                      <>
                                        {network?.overrall_Risk === "Low" && (
                                          <button
                                            className={`fs-13 text-[#70ad47] border-[#70ad47] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                            onClick={() => {
                                              addCountryCode(network);
                                              handleNetworkButton(network);
                                            }}
                                          >
                                            Low Risk
                                          </button>
                                        )}

                                        {network?.overrall_Risk ===
                                          "Medium" && (
                                            <button
                                              className={`fs-13 text-[#ffc000] border-[#ffc000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                              onClick={() => {
                                                addCountryCode(network);
                                                handleNetworkButton(network);
                                              }}
                                            >
                                              Medium Risk
                                            </button>
                                          )}

                                        {network?.overrall_Risk === "High" && (
                                          <button
                                            className={`fs-13 text-[#ff0000] border-[#ff0000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn`}
                                            onClick={() => {
                                              addCountryCode(network);
                                              handleNetworkButton(network);
                                            }}
                                          >
                                            High Risk
                                          </button>
                                        )}
                                        {network?.risk_status !== "" && (
                                          <>
                                            <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                              Last assessed:
                                              {moment
                                                .unix(
                                                  network?.updated_risk_time /
                                                  1000
                                                )
                                                .format("DD MMM YYYY")}{" "}
                                            </p>
                                            <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                              CDD Report Expires on:{" "}
                                              {network?.user_id?.cdd?.validTill}
                                            </p>
                                            <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                              ID Expiration Date:{" "}
                                              {moment(
                                                network?.user_id?.amlReport?.appData?.info?.idDocs?.find(a => a.idDocType && a.validUntil)?.validUntil
                                              ).format("DD MMM YYYY")}
                                            </p>
                                          </>
                                        )}

                                      </>
                                    )}
                                    { network.hold && <button onClick={() => {unHoldUserNetwork(network);}} className="fs-13 bg-danger text-white px-2 mt-5 h-9 !w-36 rounded-lg">On Hold</button>}
                                  </>
                                ))}
                          </div>
                        )}

                        {/* flex flex-col xl:justify-center justify-start ms:justify-end items-start ms:items-end xl:items-center */}
                        {/* justify-start ms:justify-end items-start ms:items-end xl:items-center */}

                        <div
                          className={`flex flex-col md:flex-col xl:flex-row justify-start ms:justify-end xl:justify-end items-start ms:items-end xl:items-end gap-2 xl:gap-3`}
                        >
                          <div className="flex flex-col order-2 md:order-2 xl:order-1 gap-3 justify-center items-start ms:items-end mob:items-center cstBtn">
                            {/* Accept to Share CDD Report button  */}
                            {network?.is_amp == true &&
                              network?.amp_request_cdd?.status == "pending" && (
                                <>
                                  <button
                                    className="fs-13 bg-primary text-white whitespace-nowrap xl:px-2 h-9 rounded-lg px-2"
                                    onClick={() => {
                                      appAcceptShare(network);
                                    }}
                                  >
                                    Accept to share your CDD
                                  </button>
                                </>
                              )}

                            {/* Accept to Share CDD Report button  */}
                            {network?.is_applicant == true &&
                              network?.app_request_cdd?.status == "pending" && (
                                <>
                                  <button
                                    className="fs-13 bg-primary text-white whitespace-nowrap xl:px-2 h-9 rounded-lg px-2"
                                    onClick={() => {
                                      acceptRqCddReport(network);
                                    }}
                                  >
                                    Accept to share your CDD
                                  </button>
                                </>
                              )}

                            {/* CDD request pending button  */}
                            {(network?.is_amp == true &&
                              network?.app_request_cdd?.status == "pending") ||
                              (network?.is_applicant == true &&
                                network?.amp_request_cdd?.status ==
                                "pending" && (
                                  <button className="bg-gray-300 hover:cursor-not-allowed text-primary px-2 h-9 rounded-lg">
                                    CDD Request Pending
                                  </button>
                                ))}
                          </div>

                          <div className="flex flex-col justify-center order-1 md:order-1 xl:order-2 items-start ms:items-end gap-3 mob:items-center cstBtnWidth">
                            {/* artpass ID button  */}
                            <button
                              className="fs-13 border border-[#86c1f4] text-primary px-2 h-9 rounded-lg"
                              onClick={() => {
                                viewArtpassNetworkID(network);
                              }}
                            >
                              artpass ID
                            </button>

                            {/* CDD Report &   Inspection Report button for sender  */}
                            {(network?.is_amp == true &&
                              network?.amp_request_cdd?.status == "accepted") ||
                              (network?.is_applicant == true &&
                                network?.amp_request_cdd?.status ==
                                "accepted" && (
                                  <>
                                    <button
                                      className="fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg
                            "
                                      onClick={() => {
                                        viewNetworkCDDReport(network);
                                      }}
                                    >
                                      CDD Report
                                    </button>
                                    {network?.update_available && <button
                                      className="fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                      onClick={() => {
                                        viewNetworkInspectionReport(network);
                                      }}
                                    >
                                      Inspection Report
                                    </button>}
                                    {!network?.update_available && <button
                                      className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                      onClick={() => {
                                        viewNetworkInspectionReport(network);
                                      }}
                                    >
                                      Inspection Report
                                    </button>}
                                    {/* <button
                                      className="fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                      onClick={() => {
                                        viewNetworkCompleteIR(network);
                                      }}
                                    >
                                      Complete IR
                                    </button> */}
                                  </>
                                ))}

                            {/* Request CDD Report button  */}
                            {/* {network?.is_amp == true &&
                            !network?.app_request_cdd && (
                              <button
                                className="border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                                onClick={() => {
                                  networkCDDReport(network);
                                }}
                              >
                                Request CDD Report
                              </button>
                            )} */}
                            {/* Request CDD Report button  */}
                            {/* {network?.is_applicant == true &&
                            !network?.amp_request_cdd && (
                              <button
                                className="border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                                onClick={() => {
                                  ampRequestCddReport(network);
                                }}
                              >
                                Request CDD Report
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
      {users.length >= 0 && <>
        {users.length !== 0 && (
          <p className="font-bold text-lg pb-5 pt-3 flex justify-center items-center w-full tracking-wide">
            Unconnected Users
          </p>
        )}
        {(users || []).map((user, i) => {
          return (
            <div className="sm:flex  w-full gap-4 mb-6" key={i}>
              <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center  mb-4 md:mb-0 ">
                <img
                  className="h-[98px] w-[120px] object-fill"
                  // src={"https://artpass.id" + "/" + connector?.avatar}
                  src={baseUrl + "/" + user?.avatar}
                  alt=""
                />
              </div>

              <div className="2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 tab_design">
                <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                  <div className="mb-4 text-[15px] w-full sm:w-auto">
                    <p className="font-semibold">
                      {user?.company?.name}
                    </p>
                    <p
                      className={`${"text-primary underline cursor-pointer"
                        }`}
                    >
                      {user?.fname + " " + user?.lname}
                    </p>
                    <p className="font-semibold">{user?.username} </p>
                  </div>
                </div>
                <div
                  className={`grid grid-cols-1 ${toggle === true && "xl:grid-cols-2"
                    } text-[14px] lg:text-[15px] w-full justify-center xl:justify-end items-start gap-2 xl:gap-3`}
                >
                  <button
                    className="fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                    onClick={() => {
                      connectInvisibly(user);
                    }}
                  >Connect Invisibly</button>
                </div>
              </div>
            </div>
          );
        })}
      </>}
    </>
  );
};

export default ConnectionsRequests;
