import React, { useEffect, useState } from "react";
import compliance from "../assets/images/compliance.jpg";
import { BsGlobe } from "react-icons/bs";
import {
  SlTarget,
  SlPeople,
  SlClock,
  SlShield,
  SlEmotsmile,
  SlList,
  SlGlobeAlt,
  SlUserUnfollow,
  SlOrganization,
  SlLock,
  SlLike,
} from "react-icons/sl";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import Signin from "../pages/Signin";
import "../css/login.css";
import "../css/default.css";
import { baseUrl } from "../services/services.profile";

const Compliance = () => {
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isHeaderPersonal, setIsHeaderPersonal] = useState(false);

  const changeNavbar = () => {
    if (window.scrollY >= 20) {
      setIsHeaderPersonal(true);
    } else {
      setIsHeaderPersonal(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  const Data = [
    {
      icon: <BsGlobe className="w-11 h-11 !text-white" />,
      title: "Global KYC compliance",
      desc: "artpass ID’s identity verification platform is globally applicable, as our approach and methodology are carefully designed according to the FATF recommendations regarding AML and CTF requirements, which serves as the international basis for local AML laws.",
    },
    {
      icon: <SlTarget className="w-11 h-11 !text-white" />,
      title: "Risk-based approach",
      desc: "Our system is based on a risk-based approach and follows global and local regulatory norms (FATF, HMRC). Our expertise in compliance and our provision of a range of technologies help us and financial authorities to speak in a common language for our customers.",
    },
    {
      icon: <SlPeople className="w-11 h-11 !text-white" />,
      title: "KYC verification",
      desc: "The artpass ID platform is created with tools that enable completely automatic KYC verification as well as checks based upon human review in line with the current European legislation on non-face-to-face customer identification in the banking industry.",
    },
    {
      icon: <SlClock className="w-11 h-11 !text-white" />,
      title: "Ongoing monitoring",
      desc: "We constantly monitor all the existing user profiles to manage the risks. The system will indicate if a user has been put on a Sanctions list or his document has expired. You can react immediately in case of any changes.",
    },
    {
      icon: <SlShield className="w-11 h-11 !text-white" />,
      title: "ISO 27001:2022",
      desc: "We are ISO 27001:2022 Certified with a compliant GDPR program while providing training to our staff and conducting meetings on how important data protection is for the entire core team.",
    },
    {
      icon: <SlEmotsmile className="w-11 h-11 !text-white" />,
      title: "Customer consent",
      desc: "We receive customer consent before processing personal data. The request for consent is placed in a separate checkbox before verification, so a user clearly understands what they are agreeing with.",
    },
    {
      icon: <SlList className="w-11 h-11 !text-white" />,
      title: "Clear privacy policy",
      desc: "In our policy it is clearly stated how the user’s data is going to be used and for how long. It gives users transparency and information about the purposes and methods of processing.",
    },
    {
      icon: <SlGlobeAlt className="w-11 h-11 !text-white" />,
      title: "EU & UK data centers & DPO",
      desc: "We store all the data in Microsoft Azure Cloud GDPR compliant servers, which are located in the United Kingdom and the European Union. Overall responsibility for all data lies on the DPO (Data Protection Officer).",
    },
    {
      icon: <SlUserUnfollow className="w-11 h-11 !text-white" />,
      title: "Right to revoke consent",
      desc: "Our users have the right to withdraw consent within a reasonable timeframe. To revoke an approval, all they have to do is delete their account in their profile screen or send a message to support@artpass.id.",
    },
    {
      icon: <SlOrganization className="w-11 h-11 !text-white" />,
      title: "Secure data storage",
      desc: "User data is stored in encrypted format on our servers, which are kept at Uptime Institute classified Tier III data centers compliant with TIA-942 and PCI DSS standards, protected by specially audited security personnel. ",
    },
    {
      icon: <SlLock className="w-11 h-11 !text-white" />,
      title: "Encryption technologies ",
      desc: "Data is encrypted based on the TLS 1.2 protocol. Decryption keys are stored separately from the actual data, so people with criminal intent won’t get access to your sensitive data.",
    },
    {
      icon: <SlLike className="w-11 h-11 !text-white" />,
      title: "Server monitoring",
      desc: "Our applications are constantly monitored and in the event of an issue we are notified with a screenshot of the error, and a second-by-second timeline with the fastest 30-second checks.",
    },
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
      />
      <div>
        <span
          id="menu-remove"
          className={`fixed top-0 left-0 z-20 ${
            toggle ? "block" : "hidden"
          }  h-screen w-full bg-[rgba(000,0,0,0.5)]`}
          onClick={() => {
            setToggle(!toggle);
          }}
        ></span>
      </div>
      <header id="header">
        <nav
          className={`${
            isHeaderPersonal
              ? "navbar navbar-expand navbar-fixed sub items-center flex justify-center visible navbar-sticky"
              : "navbar navbar-expand navbar-fixed sub items-center flex justify-center"
          }`}
        >
          <div className="container header flex px-[25px] py-5 justify-between items-center">
            <Link className="navbar-brand whitespace-nowrap" to="/">
              <span className="brand">
                artpass
                <span className="last"> ID </span>
              </span>
            </Link>

            <ul className="flex justify-center items-center lg:gap-8 gap-3 text-white list-none">
              <li className="p-2 hidden lg:block">
                <Link
                  to="/personal"
                  className="!text-white hover:!text-[#507025]"
                >
                  Personal
                </Link>
              </li>
              <li className="p-2 hidden lg:block">
                <Link
                  to="/business"
                  className="!text-white hover:!text-[#507025]"
                >
                  Business
                </Link>
              </li>
              <li className="p-2">
                <a
                  onClick={() => setModal(true)}
                  className="!text-white flex justify-center items-center hover:!text-[#507025]"
                  data-toggle="modal"
                  data-target="#artpass-signin"
                >
                  <AiOutlineUser className="h-6 w-7" />
                </a>
              </li>
              <li className="p-2 hidden lg:block">
                <a
                  href="https://onboarding.artpass.id"
                  target="_blank"
                  className="border px-6 py-[10px] h-[40px] border-solid border-white hover:border-none hover:bg-[#507025] !text-white"
                >
                  Join us now!
                </a>
              </li>
              <button
                data-collapse-toggle="divbar-sticky"
                type="button"
                onClick={() => {
                  setToggle(!toggle);
                }}
                id="menu-icon"
                className="flex items-center w-9 h-9 min-w-[36px] justify-center text-sm text-white rounded-full lg:hidden"
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </ul>
          </div>
        </nav>
      </header>

      <div
        className={`fixed top-0 right-0 left-0 z-50 sm:w-3/4  ${
          !toggle ? "-translate-x-full" : ""
        } h-[50%] w-full max-w-[20rem]  bg-white shadow-lg transition duration-500`}
        id="menu-items"
      >
        <ul className="list-none p-6 ">
          <li className="py-2 px-4">
            <a href="/" className="!font-bold !text-lg">
              Home
            </a>
          </li>
          <li className="py-2 px-4">
            <a
              href="https://onboarding.artpass.id"
              target="_blank"
              className="!font-bold !text-lg"
            >
              Join us
            </a>
          </li>
          <li className="py-2 px-4">
            <a
              href="#"
              data-toggle="modal"
              data-target="#artpass-signin"
              className="!font-bold !text-lg"
            >
              Sign in
            </a>
          </li>
          <li className="py-2 px-4">
            <a href="/business" className="!font-bold !text-lg">
              Business
            </a>
          </li>
          <li className="py-2 px-4">
            <a href="/personal" className="!font-bold !text-lg">
              Personal
            </a>
          </li>
        </ul>
      </div>
      {/* section 1 */}
      <section
        id="slider"
        className="bg-[#080d10] p-0 !text-[#ffffff] relative"
      >
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <div className="swiper-slide slide-center">
              <img
                src={compliance}
                alt="Full Image"
                className="full-image"
                data-mask="80"
              />
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section id="services" className="!bg-[#000000]">
        <div className="2xl:mx-[250px] xl:mx-[170px] lg:mx-[30px] mx-[10px] ">
          <div className="md:max-w-[75%] 2xl:max-w-[78%] xl:max-w-[82%] w-full px-1 self-center text-center md:text-left mb-[70px]">
            <h2 className="!text-white mb-6 !text-[32px] lg:!text-[54px]">
            The most advanced security certification and technologies to keep you compliant with AML KYC/KYB requirements
            </h2>
            <p className="text-white">
              We are leaders in providing compliant KYC/AML compliance for the
              art market with a set of cutting-edge technologies and a team of
              experienced and renowned professionals. These are our compliance
              and security features.
            </p>
          </div>

          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  justify-center gap-5 w-full">
            {Data.map((item, i) => (
              <div className="mb-3" key={i} data-aos="hover:fade-up">
                <div className="card !bg-[#ffffff09] !h-full">
                  {item?.icon}
                  <h4 className="!text-white">{item?.title}</h4>
                  <p className="text-white">{item?.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <footer>
        <section id="copyright" className="p-4 !bg-black">
          <div className="flex justify-center items-center">
            <div className="container">
              <div className="p-2 text-center text-white text-base">
                <p>
                  <a
                    href={baseUrl+"/terms/"}
                    target="_blank"
                    className="!text-white font-light"
                  >
                    Terms & Conditions{" "}
                  </a>
                  <a
                    href={baseUrl+"/privacy_policy.html"}
                    target="_blank"
                    className="!text-white font-light"
                  >
                    {" "}
                    | Privacy Policy{" "}
                  </a>
                  <a
                    href={baseUrl+"/cookie_policy.html"}
                    target="_blank"
                    className="!text-white font-light"
                  >
                    {" "}
                    | Cookie Policy{" "}
                  </a>
                  <a
                    href={baseUrl+"/disclaimer.html"}
                    target="_blank"
                    className="!text-white font-light"
                  >
                    {" "}
                    | Disclaimer{" "}
                  </a>
                  <a
                    href={"baseUrl+/#artpass-signin"}
                    target="_blank"
                    className="!text-white font-light"
                  >
                    {" "}
                    | Sign in{" "}
                  </a>
                  <a
                    href="#artpass-signin"
                    data-dismiss="modal"
                    data-toggle="modal"
                    className="!text-white font-light"
                  >
                    | Sign in
                  </a>
                  <a
                    href="https://onboarding.artpass.id/#/"
                    target="_blank"
                    className="!text-white font-light"
                  >
                    {" "}
                    | Join us{" "}
                  </a>
                </p>
              </div>
              <div className="text-center text-white text-xs">
                artpass ID Ltd. is registered as a Data Processor with the UK
                Information Commissioner’s Office under number ZA633666. Our
                data protection officer can be contacted by email at
                dpo@artpass.id. © 2021 artpass ID Ltd. All rights reserved.
                artpass ID, 1-Click KYC and 1-Click Compliance are registered
                trademarks by artpass ID Ltd. Company number: 12402898
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Compliance;
