import { Button, Checkbox, Form, Input, Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { useRoutes } from "react-router";
import { Navigate, Routes, Route,BrowserRouter,Switch } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import ArtpassID from "./pages/Artpass";
import Profile from "./pages/Profile";
import Dealrooms from "./pages/Dealrooms";
import DealroomsCreate from "./pages/DealroomsCreate";
import RequestIDVCDD from "./pages/RequestIDVCDD";
import ConnectionsRequest from "./pages/ConnectionsRequest";
import ConnectionsCode from "./pages/ConnectionsCode";
import Settings from "./pages/Settings";
import Pricing from "./pages/Pricing";
import DealroomsPast from "./pages/DealroomsPast";
import Connections from "./pages/Connections";
import ConnectionsRequests from "./pages/ConnectionsRequests";
import Membership from "./pages/Membership";
import Logout from "./pages/Logout";
import { getProfile, login } from "./services/services.profile";
import ConnectionsOnboarding from "./pages/ConnectionsOnboarding";
import Login from "./pages/Login";
import Personal from "./pages/Personal";
import Business from "./pages/Business";
import Compliance from "./pages/Compliance";
import Screen from "./pages/Screen";
import ScreeningResults from "./pages/ScreeningResults";

const links = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "My artpass ID",
        path: "/",
        element: <ArtpassID />,
      },
      {
        label: "Request KYC",
        path: "/request-idv-and-cdd",
        // element: <RequestIDVCDD />,
        children: [
          {
            label: "New Onboarding",
            path: "/request-idv-and-cdd",
            element: <RequestIDVCDD />,
          },
          {
            label: "Request to Connect",
            path: "/request-idv-and-cdd/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/request-idv-and-cdd/code",
            element: <ConnectionsCode />,
          },
        ],
      },
      {
        label: "Connections",
        path: "/connections",
        children: [
          {
            label: "Whitelisted",
            path: "/connections",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/connections/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding in progress",
            path: "/connections/onboarding",
            element: <ConnectionsOnboarding />,
          },
        ],
      },
      // {
      //   label: "AML Screening",
      //   path: "/screening",
      //   children: [
      //     {
      //       label: "Screen",
      //       path: "/screening",
      //       element: <Screen/>,
      //     },
      //     {
      //       label: "Screening Results",
      //       path: "/screening/results",
      //       element: <ScreeningResults/>,
      //     }
      //   ]
      // },
      // {
      //   label: "Dealrooms",
      //   path: "/dealrooms",
      //   children: [
      //     {
      //       label: "My Dealrooms",
      //       path: "/dealrooms",
      //       element: <Dealrooms />,
      //     },
      //     {
      //       label: "Create Dealroom",
      //       path: "/dealrooms/create",
      //       element: <DealroomsCreate />,
      //     },
      //     {
      //       label: "Past Dealrooms",
      //       path: "/dealrooms/past",
      //       element: <DealroomsPast />,
      //     },
      //   ],
      // },
      {
        label: "Support",
        path: "/support",
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: "Settings",
        path: "/user/settings",
        element: <Settings />,
      },
      {
        label: "Pricing",
        path: "/user/pricing",
        element: <Pricing />,
      },
      {
        label: "Membership",
        path: "/user/membership",
        element: <Membership />,
      },
     
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

// const links = [
//   {
//     icon: <AiOutlineHome />,
//     label: 'Home',
//     path: '',
//     children: [
//       {
//         label: 'Search Jobs',
//         path: '/'
//       },
//       {
//         label: 'My Work',
//         children: [
//           { label: 'Saved Jobs' },
//           { label: 'Proposals' },
//           { label: 'Profile' },
//           { label: 'Upwork Readiness Test' },
//           { label: 'My Project Dashboard' },
//         ]
//       },
//       {
//         label: 'My Jobs',
//         children: [
//           { label: 'My Jobs' },
//           { label: 'All Contracts' },
//           { label: 'Work Diary' },
//         ]
//       },
//       {
//         label: 'Reports',
//         children: [
//           { label: 'My Reports' },
//           { label: 'Connect History' },
//         ]
//       },
//       {
//         label: 'Messages',
//       }
//     ]
//   },
//   {
//     icon: <AiOutlineUser />,
//     label: 'User',
//     path: '/user',
//     children: [
//       { label: 'My Profile' },
//       { label: 'Membership & Connects' },
//       { label: 'Contact Info' },
//       { label: 'Profile Settings' },
//       { label: 'My Teams' },
//       { label: 'Connected Services' },
//       { label: 'Logout' },
//     ]
//   }
// ]

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const onBreakPoints = (e) => {
    setCollapsed(e);
  };

  const content = useRoutes(links);

  useEffect(() => {
    getProfile()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {});
  }, []);

  const onFinishFailed = () => {};
  const onFinish = async () => {
    const user = await login(email, password);
    if (user.error) {
      alert("wrong details");
    } else {
      getProfile()
        .then((user) => {
          setUser(user);
        })
        .catch((err) => {});
    }
  };

  return user ? (<>
    <Layout className="main" hasSider>
      <Layout>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content>{content}</Content>
      </Layout>
      <Sidebar
        collapsed={collapsed}
        links={links}
        onBreakPoints={onBreakPoints}
      />


    </Layout>
    {/* <Routes>
    <Route path="/user/mem" element={<Membership /> } />
  </Routes> */}
  </>
  ) : (
    <Layout>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/personal" element={<Personal />} />
        <Route path="/business" element={<Business />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
};

export default App;
