import React from "react";
import { IoMdClose } from "react-icons/io";

const FilterModal = ({
  filterOpen,
  setFilterOpen,
  risk,
  setRisk,
  userType,
  setUserType,
  riskRating,
  setRiskRating,
  handleSubmit,
  fill,
  setFill,
  isRiskRating,
  setIsRiskRating,
  riskArr,
  setRiskArr,
}) => {
  const userList = [
    { id: 8, name: "Natural persons" },
    { id: 9, name: "Legal entities" },
  ];


  const handleConnection = async (item) => {   
    if (riskRating.includes(item.name)) {
      let arr1 = []
      arr1 = riskRating.filter((el) => el !== item.name)
      setRiskRating(arr1)
    } else {
      setRiskRating([...riskRating,item?.name])
    }  
  };
  
  const handleRisk = async (item) => {
    if (risk.includes(item.name)) {
      let arr2 = [];
      arr2 = risk.filter((el) => el !== item.name);
      setRisk(arr2);
    } else {
      setRisk([...risk, item?.name]);
    }
  };

  const handleFilter = async () => {
    setFilterOpen(false)
    setIsRiskRating([])
  }

  return (
    <>
      {filterOpen && (
        <div className="fixed inset-0 z-30 overflow-hidden top-0">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={handleFilter}
          ></div>
          <div className="flex justify-center items-center h-full w-full min-h-screen">
            <div className="relative mx-auto bg-white rounded-md shadow-lg p-5 overflow-auto w-96 h-auto">
              <div
                className="flex justify-end cursor-pointer absolute top-5 left-5 right-[25px] pr-2"
                onClick={handleFilter}
              >
                <IoMdClose
                  size={24}
                  className="text-gray-500  hover:text-primary flex justify-center items-center"
                />
              </div>
              <div className="flex justify-center flex-col px-4 pb-2 tracking-wide">
                <p className="text-lg font-semibold">
                  Filter on any combination.
                </p>
                <div className="flex flex-col justify-center items-center gap-8 pt-5">
                  <div className="flex flex-col justify-center items-center gap-[10px]">
                    {riskArr?.map((item, index) => (
                      <button
                        type="button"
                        key={index}
                        onClick={() => {
                          handleRisk(item);
                        }}
                        className={`text-[12px] ${item?.border} border-2 tracking-wide relative ${item?.text} w-40 px-2 h-8 rounded-lg font-semibold`}
                      >
                        {risk.includes(item?.name) && (
                          <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-[9px] left-6" />
                        )}
                        {item?.name} Risk
                      </button>
                    ))}
                  </div>

                  <div className="flex flex-col justify-center items-center gap-[10px]">
                    {isRiskRating?.map((item, index) => (
                      <button
                        type="button"
                        key={index}
                        onClick={() => handleConnection(item)}
                        className="text-[12px] border-black border-2 relative font-semibold tracking-wide text-black w-40 px-2 h-8 rounded-lg"
                      >
                        {riskRating.includes(item?.name) && (
                          <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-[9px] left-5" />
                        )}
                        {item?.name}
                      </button>
                    ))}
                  </div>

                  <div className="flex flex-col justify-center items-center gap-[10px]">
                    {userList?.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => {
                          setUserType(`${item?.name}`);
                        }}
                        className="text-[12px] border-black border-2 font-semibold tracking-wide relative text-black w-40 px-2 h-8 rounded-lg"
                      >
                        <div
                          className={`rounded-full ${
                            userType === item?.name && `bg-primary`
                          } w-[11px] h-[11px] absolute top-[9px] left-4`}
                        />
                        {item?.name}
                      </button>
                    ))}
                  </div>

                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="text-[13px] border-primary border-[1px] font-bold tracking-wide text-white bg-primary w-40 px-2 h-10 rounded-lg"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterModal;
