import { UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Table,
  Typography,
  Upload,
} from "antd";
import { useState } from "react";
import { Au, Be, Es, Fr, Nl, Us } from "react-flags-select";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
//services
import { baseUrl, getProfile } from "../services/services.profile";
//mobile input
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import en from "world_countries_lists/data/countries/en/world.json";
import "antd/dist/antd.min.css";
// import "antd-country-phone-input/dist/index.css";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [eSign, setESign] = useState("");
  const [validate,setValidate] = useState(false)
  const [showModel,setShowModel] = useState(false);
  const [link, setLink] = useState(baseUrl+"/user/meoknu");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [addressUpdate, setAddressUpdate] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [pinUpdate, setPinUpdate] = useState(false);
  const [otpRequest, setOtpRequest] = useState(false);
  const [pinEntered, setPinEntered] = useState(false);
  const [[oldPin, newPin], setPin] = useState([null, null]);
  const [phoneNumberUpdate, setPhoneNumberUpdate] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldNumber, setOldNumber] = useState("");
  const [oldCode, setOldCode] = useState("");
  const [officer, setOfficer] = useState([]);
  // const [defaultUBO, setDefaultDirIsUBO] = useState([]);
  const [dirLE, setDirLE] = useState([]);
  const [uboLE, setUboLE] = useState([]);
  const [ubo, setUbo] = useState([]);
  const [value, setValue] = useState();

  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
        Object.keys(res.location).length !== 0 && setAddressUpdate(false);
        setOldNumber(res.phone);
        setOldCode(res.phoneCode);
        setOfficer(res?.company?.officer);
        setDirLE(res?.company?.dir_LE);
        // setDefaultDirIsUBO(res?.company?.defaultDirIsUBO);
        setUbo(res?.company?.ubo);
        setUboLE(res?.company?.ubo_LE);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };
  const validation = (profile,value) => {
    if (value === "") {
      // message.error("Please fill in the signature");
      setValidate(false)
      return false;
    }
    if (value !== "/s/" + profile.lname) {
      // message.error("Please fill in the e-signature correctly");
      setValidate(false)
      return false;
    }
    if (value == "/s/" + profile.lname) {
    setValidate(true)
    }
    return true;
  };

  const customRequestUpload = async (req) => {
    console.log(req);
    var formData = new FormData();
    formData.append("avtFile", req.file);
    await axios.post(req.action, formData);
    return req.onSuccess(true);
  };

  const handleUpdate = async (name, value) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case "username":
        if (value.length < 3) {
          message.error("Username must be at least 3 characters");
          return;
        }
        setProfile({ ...profile, username: value });
        break;
      case "email":
        //email validation regex
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(value)) {
          message.error("Invalid email");
          return;
        }
        setProfile({ ...profile, email: value });
        break;

      case "onlineProfile":
        //website validation regex
        const websiteRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        if (!websiteRegex.test(value)) {
          message.error("Invalid website");
          return;
        }
        setProfile({ ...profile, onlineProfile: value });
        break;
    }
    handleSave(name, value);
  };

  const handleSave = async (name, value) => {
    axios
      .post(baseUrl+"/api/user/update", {
        [name]: value,
      })
      .then((res) => {
        message.success("Profile updated");
      })
      .catch((err) => {
        message.error("Failed to update profile");
      });
  };

  const handlePinChange = (e) => {
    if (e.target.name === "oldPin") {
      setPin([e.target.value, newPin]);
    } else {
      setPin([oldPin, e.target.value]);
    }
    //enable get code button
    if (e.target.value.length === 6 && oldPin.length === 6) {
      setPinEntered(true);
    } else {
      setPinEntered(false);
    }
  };
const deleteAccount = async (deletedUser)=>{
  axios
  .post(baseUrl+"/api/user/delete", {
    user: deletedUser,
  })
  .then((res) => {
   res && message.success("User Deleted Succesfully");
   setTimeout(()=>{
    window.location.reload();
   },2000)
  
  })
  .catch((err) => {
   err && message.error("Failed to delete User");
  });

}
  const handleOtpRequest = async () => {
    axios
      .post(baseUrl+"/auth/changepin", {
        oldPin: oldPin,
        newPin: newPin,
      })
      .then((res) => {
        if (res.data.error) {
          message.error("Invalid pin");
        } else {
          message.success("Pin changed successfully");
          setOtpRequest(false);
          setPinUpdate(false);
          // console.log("res : ", res);
          // setOtpRequest(true);
        }
      })
      .catch((err) => {
        console.log("err : ", err);
        message.error("Could not verify your pin");
      });
  };

  const handleOtpVerification = async (otp) => {
    axios
      .post(baseUrl+"/auth/nexmo/checkverifyrequest", {
        code_number: otp,
        phone: profile.phone,
      })
      .then((res) => {
        console.log("res : ", res);
        message.success("Pin changed successfully");
        setOtpRequest(false);
        setPinUpdate(false);
      })
      .catch((err) => {
        console.log("err : ", err);
        message.error("Could not verify your pin");
      });
  };

  const handlePasswordChange = async () => {
    if (oldPassword.length < 8) {
      message.error("Incorrect Old password");
    } else if (newPassword.length < 8) {
      message.error("Password should be at least 8 characters");
    } else if (newPassword !== confirmPassword) {
      message.error("Passwords do not match");
    } else {
      axios
        .put(baseUrl+"/user/password/change", {
          email: oldPassword,
          newpwd: newPassword,
        })
        .then((res) => {
          console.log("res : ", res);
          message.success("Password changed successfully");
          setPasswordUpdate(false);
        })
        .catch((err) => {
          console.log("err : ", err);
          message.error("Could not verify your pin");
        });
    }
  };

  // const handlePhoneUpdate = async () => {
  //   console.log("Phone : ", profile.phone);
  //   //phone validation regex
  //   const phoneRegex = /^\d{10}$/;
  //   if (!phoneRegex.test(profile.phone)) {
  //     message.error("Invalid phone number");
  //     setProfile({ ...profile, phone: oldNumber, phoneCode: oldCode });
  //     return;
  //   }
  // };

  const handlePhoneUpdate = async () => {
    //post data to API
    axios
      .post(baseUrl + "/api/user/update", {
        phone: profile.phone,
        phoneCode:
          profile.phoneCode[0] == "+"
            ? profile.phoneCode.slice(1)
            : profile.phoneCode,
      })
      .then((res) => {
        console.log("res : ", res);
        message.success("Phone number updated");
      })
      .catch((err) => {
        console.log("err : ", err);
        message.error("Could not update phone number");
      });
  };

  return (
    <>
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Avatar</Typography.Text>
        </Col>
        <Col>
          <Row align="bottom" gutter={32}>
            <Col flex={"none"}>
              <Avatar
                size={100}
                src={
                  imageUrl ||
                  baseUrl+"/" + profile.avatar ||
                  "https://i.pinimg.com/600x315/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.jpg"
                }
              />
            </Col>
            <Col flex={"none"}>
              <Upload
                onChange={handleChange}
                customRequest={customRequestUpload}
                // withCredentials={ true }
                action={baseUrl+"/api/uploadavt"}
              >
                <Button type="default" icon={<UploadOutlined />}>
                  Click to Upload
                </Button>
              </Upload>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider dashed />
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Username</Typography.Text>
        </Col>
        <Col flex={"auto"}>
          <Typography.Text
            name="username"
            editable={{
              suffix: "@",
              onChange: (e) => {
                handleUpdate("username", e);
              },
            }}
          >
            @{profile?.username}
          </Typography.Text>
        </Col>
      </Row>
      <Divider dashed />
      {/* NAME */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Name</Typography.Text>
        </Col>
        <Col flex={"auto"}>
          <Typography.Text>
            {profile?.fname + " " + profile?.lname}
          </Typography.Text>
          {/* <Typography.Text>Naveen Raghuvanshi</Typography.Text><br /> */}
        </Col>
        {/* <Col flex={'none'}><Button type="primary">CHANGE</Button></Col> */}
      </Row>
      <Divider dashed />
      {/* EMAIL */}
      <Row gutter={[16, 16]} align="top">
        <Col span={4}>
          <Typography.Text>Email</Typography.Text>
        </Col>
        <Col flex={"auto"}>
          <Typography.Text
            editable={{
              onChange: (e) => {
                handleUpdate("email", e);
              },
            }}
          >
            {profile.email}
          </Typography.Text>
          <br />
          {profile?.emailVerifyTime !== "" ? (
            <Typography.Text type="success">
              <AiOutlineCheck /> Email verified on{" "}
              {new Date(profile?.emailVerifyTime).toDateString()}
            </Typography.Text>
          ) : (
            <Typography.Text type="danger">
              <AiOutlineClose /> Not Verified
            </Typography.Text>
          )}
        </Col>
        {profile?.emailVerifyTime === "" && (
          <Col flex={"none"}>
            <Button type="primary">VERIFY</Button>
          </Col>
        )}
      </Row>
      <Divider dashed />
      {/* ADDRESS */}
      <Row gutter={[16, 16]} align="top" className="addressWrap">
        <Col span={4}>
          <Typography.Text>Address</Typography.Text>
        </Col>
        {addressUpdate ? (
          <Col span={17}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Text>
                  Enter your principal address of residence or business
                </Typography.Text>
              </Col>
              {/* STREET ADDRESS */}
              <Col span={12}>
                <Input
                  value={profile?.location?.street?.number}
                  placeholder="Street Number"
                />
              </Col>
              <Col span={12}>
                <Input
                  value={profile?.location?.street?.name}
                  placeholder="Street Name"
                />
              </Col>

              <Col span={24}>
                <Row gutter={8}>
                  {/* ZIP CODE  */}
                  <Col span={8}>
                    <Input
                      value={profile?.location?.zipcode}
                      placeholder="Zip Code"
                    />
                  </Col>
                  {/* CITY  */}
                  <Col span={8}>
                    <Input value={profile?.location?.city} placeholder="City" />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={profile?.location?.country?.name}
                      editable={true}
                      placeholder="Country"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col span={17}>
            <Row gutter={[16, 16]}>
              {/* STREET ADDRESS */}
              <Col span={24}>
                <Typography.Text>
                  {profile?.location?.street?.number +
                    " " +
                    profile?.location?.street?.name}
                </Typography.Text>
              </Col>

              <Col span={24}>
                <Row gutter={8}>
                  {/* ZIP CODE  */}
                  <Col span={8}>
                    <Typography.Text>
                      {profile?.location?.zipcode}
                    </Typography.Text>
                  </Col>
                  {/* CITY  */}
                  <Col span={8}>
                    <Typography.Text>{profile?.location?.city}</Typography.Text>
                  </Col>
                  {/* STATE  */}
                  <Col span={8}>
                    <Typography.Text>
                      {profile?.location?.country?.name}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}

        {addressUpdate ? (
          <Col span={3}>
            <Button onClick={() => setAddressUpdate(false)} type="primary">
              SAVE
            </Button>
          </Col>
        ) : (
          <Col span={3}>
            <Button type="primary" onClick={() => setAddressUpdate(true)}>
              UPDATE
            </Button>
          </Col>
        )}
      </Row>
      <Divider dashed />
      {/* ONLINE PROFILE */}
      <Row gutter={[16, 16]} align="top">
        <Col span={4}>
          <Typography.Text>Online Profile</Typography.Text>
        </Col>
        <Col flex={"auto"}>
          <Typography.Link
            name="onlineProfile"
            editable={{ onChange: (e) => handleUpdate("onlineProfile", e) }}
            href={profile?.onlineProfile}
            target="_blank"
          >
            {profile?.onlineProfile}
          </Typography.Link>
        </Col>
      </Row>
      <Divider dashed />
      {/* PASSWORD */}
      <Row gutter={[16, 16]} align="top" className="passWrap">
        <Col span={4}>
          <Typography.Text>Password</Typography.Text>
        </Col>
        {passwordUpdate ? (
          <Col span={17}>
            <Row gutter={[16, 16]}>
              <Col span={20}>
                <Row gutter={8}>
                  {/* Current Password  */}
                  <Col span={8}>
                    <Input
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                      placeholder="Enter your current password"
                    />
                  </Col>
                  {/* NEW PASSWORD  */}
                  <Col span={8}>
                    <Input
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter your new password"
                    />
                  </Col>
                  {/* CONFIRM NEW PASSWORD */}
                  <Col span={8}>
                    <Input
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm your new password"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col span={17}>
            <Row gutter={[16, 16]}>
              {/* PASSWORD */}
              <Col span={"auto"}>
                <Typography.Text>********</Typography.Text>
              </Col>
            </Row>
          </Col>
        )}

        {passwordUpdate ? (
          <Col span={3}>
            <Button onClick={handlePasswordChange} type="primary">
              SAVE
            </Button>
          </Col>
        ) : (
          <Col span={3}>
            <Button type="primary" onClick={() => setPasswordUpdate(true)}>
              UPDATE
            </Button>
          </Col>
        )}
      </Row>
      <Divider dashed />
      {/* PIN */}
      <Row gutter={[16, 16]} align="top" className="pinwrap">
        <Col span={4}>
          <Typography.Text>Pin</Typography.Text>
        </Col>
        {pinUpdate ? (
          <Col span={17}>
            {otpRequest ? (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Input placeholder="Enter the OTP" />
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={handleOtpVerification}
                      style={{ alignSelf: "center" }}
                      type="primary"
                    >
                      Verify Code
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <Row gutter={[16, 16]}>
                {/* PIN CREDENTIALS */}
                <Col span={12}>
                  <Input
                    value={oldPin}
                    name="oldPin"
                    onChange={(e) => handlePinChange(e)}
                    placeholder="Enter OLD PIN"
                  />
                </Col>
                <Col span={12}>
                  <Input
                    value={newPin}
                    name="newPin"
                    onChange={(e) => handlePinChange(e)}
                    placeholder="Enter NEW PIN"
                  />
                </Col>

                {/* <Col span={6}>
                  <Typography>Get 2-FA mobile OTP</Typography>
                </Col> */}
                <Col span={6}>
                  <Button
                    onClick={() => {
                      handleOtpRequest();
                    }}
                    style={{ alignSelf: "center" }}
                    type="primary"
                    disabled={!pinEntered}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        ) : (
          <Col span={17}>
            <Row gutter={[16, 16]}>
              {/* PIN */}
              <Col span={24}>
                <Typography.Text>******</Typography.Text>
              </Col>
            </Row>
          </Col>
        )}

        {!pinUpdate && (
          <Col span={3}>
            <Button type="primary" onClick={() => setPinUpdate(true)}>
              UPDATE
            </Button>
          </Col>
        )}
      </Row>
      <Divider dashed />
      {/* PHONE NUMBER */}
      <Row gutter={[16, 16]} align="top">
        <Col span={4}>
          <Typography.Text>Phone Number</Typography.Text>
        </Col>
        {phoneNumberUpdate ? (
          <>
            <Col span={17}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <PhoneInput
                    value={value}
                    onChange={(value, country, e, formattedValue) => {
                      setValue(value);
                      //extract the country code from the formatted value and phone number
                      const countryCode = formattedValue.split(" ")[0];
                      //extract phonenumber by removing country code from 'value'
                      const phoneNumber = value.slice(countryCode.length - 1);
                      setProfile({
                        ...profile,
                        phone: phoneNumber,
                        phoneCode: countryCode,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            {" "}
            <Col span={17}>
              <Typography.Text>
                {"+" + profile?.phoneCode + " " + profile?.phone}
              </Typography.Text>
              <br />
              {profile?.verifyPhoneTime && (
                <Typography.Text strong type="success">
                  <small>
                    Verified on{" "}
                    {new Date(profile?.verifyPhoneTime).toLocaleString()}
                  </small>
                </Typography.Text>
              )}
            </Col>
          </>
        )}
        {phoneNumberUpdate ? (
          <Col span={3}>
            <Button
              onClick={() => {
                handlePhoneUpdate();
                setPhoneNumberUpdate(false);
              }}
              type="primary"
            >
              SAVE
            </Button>
          </Col>
        ) : (
          <Col span={3}>
            <Button
              onClick={() => {
                setPhoneNumberUpdate(true);
              }}
              type="primary"
            >
              UPDATE
            </Button>
          </Col>
        )}
      </Row>
      <Divider dashed />
      <Row gutter={[16, 16]} align="top" className="tblRow">
        <Col span={4}>
          <Typography.Text style={{ display: "block", marginTop: "6px" }}>
            Directors / Officers
          </Typography.Text>
        </Col>
        <Col span={20}>
          <div>
            <table className="w-full">
              <thead className="bg-[#fafafa] text-left gap-4">
                <tr>
                  <th className="p-2">Name</th>
                  <th className="p-2">DOB</th>
                  <th className="p-2">Nationality</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white text-left text-[14px] border-b ">
                  <td className="p-2">
                    {profile?.amlReport?.appData?.info?.firstName?.toUpperCase() +
                      " " +
                      profile?.amlReport?.appData?.info?.lastName?.toUpperCase()}
                  </td>
                  <td className="p-2">
                    {profile?.amlReport?.appData?.info?.dob}
                  </td>
                  <td className="p-2">
                    {profile?.amlReport?.appData?.info?.country}
                  </td>
                </tr>

                {(officer || []).map((item, i) => {
                  return (
                    <tr className="bg-white border-b text-[14px]" key={i}>
                      <td className="p-2">{item?.fname + " " + item?.lname}</td>
                      <td className="p-2">{item?.dob}</td>
                      <td className="p-2">{item?.country}</td>
                    </tr>
                  );
                })}

                {(dirLE || []).map((item, i) => {
                  return (
                    <tr className="bg-white border-b text-[14px]" key={i}>
                      <td className="p-2">{item?.company?.name}</td>
                      <td className="p-2">{item?.company?.full_addr}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Divider dashed />
      <Row gutter={[16, 16]} align="top">
        <Col span={4}>
          <Typography.Text style={{ display: "block", marginTop: "6px" }}>
            UBOs
          </Typography.Text>
        </Col>
        <Col span={20}>
          <div>
            <table className="w-full">
              <thead className="bg-[#fafafa] text-left gap-4">
                <tr>
                  <th className="p-2">Name</th>
                  <th className="p-2">DOB</th>
                  <th className="p-2">Nationality</th>
                </tr>
              </thead>
              <tbody>
                {profile?.company?.defaultDirIsUBO && (
                  <>
                    <tr className="bg-white border-b text-[14px]">
                      <td className="p-2">
                        {profile?.amlReport?.appData?.info?.firstName.toUpperCase() +
                          " " +
                          profile?.amlReport?.appData?.info?.lastName.toUpperCase()}
                      </td>
                      <td className="p-2">
                        {profile?.amlReport?.appData?.info?.dob}
                      </td>
                      <td className="p-2">
                        {profile?.amlReport?.appData?.info?.country}
                      </td>
                    </tr>
                  </>
                )}

                {(officer || []).map((item, i) => {
                  return (
                    <>
                      {item?.isUbo == true && (
                        <tr className="bg-white border-b text-[14px]" key={i}>
                          <td className="p-2">
                            {item?.fname + " " + item?.lname}
                          </td>
                          <td className="p-2">{item?.dob}</td>
                          <td className="p-2">{item?.country}</td>
                        </tr>
                      )}
                    </>
                  );
                })}

                {(ubo || []).map((item, i) => (
                  <tr className="bg-white border-b text-[14px]" key={i}>
                    <td className="p-2">{item?.fname + " " + item?.lname}</td>
                    <td className="p-2">{item?.dob}</td>
                    <td className="p-2">
                      {item?.country?.iso2code ? item?.country?.iso2code : "-"}
                    </td>
                  </tr>
                ))}

                {(dirLE || []).map((item, i) => {
                  return (
                    <>
                      {item?.isUbo == true && (
                        <tr className="bg-white border-b text-[14px]" key={i}>
                          <td className="p-2">{item?.company?.name}</td>
                          <td className="p-2">{item?.company?.full_addr}</td>
                        </tr>
                      )}
                    </>
                  );
                })}

                {(uboLE || []).map((item, i) => {
                  return (
                    <tr className="bg-white border-b text-[14px]" key={i}>
                      <td className="p-2">{item?.company?.name}</td>
                      <td className="p-2">{item?.company?.full_addr}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Divider dashed />
      <Col span={5}>
            <Button
              onClick={() => setShowModel(true)}
              type="submit"
              className="ant-btn-danger"
            >
              Delete My Account
            </Button>
          </Col>
      
        <Modal title="Are you sure you want to delete this account ?"
          visible={showModel}
          okButtonProps={{ disabled: !validate}}
          onOk={() => {
             deleteAccount(profile);
             setShowModel(false);
          }}
          onCancel={() => {
            setShowModel(false);
          }}>
          
          <Row gutter={[16, 16]} align="middle">
          <Col span={8}>
            <Typography.Text>
              eSign /s/last name. Example: /s/{profile.lname}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Input
              value={eSign}
              onChange={(e) => {
                setESign(e.target.value);
                validation(profile,e.target.value);
              }}
              placeholder="/s/Lastname"
            />
          </Col>
        </Row>
        </Modal>
          <Divider dashed />
    </>
  );
};

export default Profile;
