/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import "../css/login.css";
import "../css/default.css";
import downloadApp from "../assets/images/download_app.png";
import downloadApp2 from "../assets/images/download_apk2.png";
import videoBg from "../assets/images/video-bg.png";
import user1 from "../assets/images/user1.png";
import user2 from "../assets/images/user2.png";
import user3 from "../assets/images/user3.png";
import scanAndConnectHeader from "../assets/images/scan_and_connect_header.jpg";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { message } from "antd";
import { ErrorMessage, Formik } from "formik";
import { baseUrl } from "../services/services.profile";
import axios from "axios";
import * as Yup from "yup";
import VideoModal from "../components/modal/VideoModal";
import { BsArrowUp } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import Signin from "./Signin";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import eu from "../assets/images/eu.png";
import ccpa from "../assets/images/ccpa.png";
import gdpr from "../assets/images/GDPR.png";
import uk from "../assets/images/uk.png";
import soc2 from "../assets/images/soc2.png";
import sha256 from "../assets/images/sha256.png";
import iso from "../assets/images/iso.png";

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isHeader, setIsHeader] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const contactApi = async (email, name, mess) => {
    const res = await axios
      .post(baseUrl + "/api/subscribedEmail", {
        email,
        name,
        mess,
      })
      .then((res) => {
        console.log(res, "resss");
        message.success(res?.data?.msg);
      })
      .catch((err) => {
        message.error(res?.data?.msg);
      });
  };

  const changeNavbar = () => {
    if (window.scrollY >= 20) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  useEffect(() => {
    if (isOpen === true) {
      document.body.style.overflow = "hidden";
    }
  }, [isOpen]);

  return (
    <div>
      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
      />
      <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Formik
        initialValues={{
          email: "",
          message: "",
          name: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter valid email")
            .required("Please enter your email"),
          message: Yup.string().required("Please enter your message"),
          name: Yup.string().required("Please enter your name"),
        })}
        onSubmit={(values) => {
          let email = values.email;
          let mess = values.message;
          let name = values.name;
          contactApi(email, mess, name);
        }}
      >
        {({ handleSubmit, handleChange, handleBlur }) => (
          <div>
            <div>
              <span
                id="menu-remove"
                className={`fixed top-0 left-0 z-20 ${
                  toggle ? "block" : "hidden"
                }  h-screen w-full bg-[rgba(000,0,0,0.5)]`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></span>
            </div>
            <header id="header">
              <nav
                className={`${
                  isHeader
                    ? "navbar navbar-expand navbar-fixed sub items-center flex justify-center visible navbar-sticky"
                    : "navbar navbar-expand navbar-fixed sub items-center flex justify-center"
                }`}
              >
                <div className="container header flex px-[25px] py-5 justify-between items-center">
                  <Link className="navbar-brand whitespace-nowrap" to="/">
                    <span className="brand">
                      artpass
                      <span className="last"> ID </span>
                    </span>
                  </Link>

                  <ul className="flex justify-center items-center lg:gap-4 gap-3 text-white list-none">
                    <li className="p-2 hidden lg:block">
                      <Link
                        to="/personal"
                        className="!text-white hover:!text-[#507025] !text-[16px] !font-medium"
                      >
                        Personal
                      </Link>
                    </li>
                    <li className="p-2 hidden lg:block">
                      <Link
                        to="/business"
                        className="!text-white hover:!text-[#507025] !text-[16px] !font-medium"
                      >
                        Business
                      </Link>
                    </li>
                    <li className="p-2">
                      <a
                        onClick={() => setModal(true)}
                        className="!text-white flex justify-center items-center hover:!text-[#507025]"
                        data-toggle="modal"
                        data-target="#artpass-signin"
                      >
                        <AiOutlineUser className="h-6 w-7" />
                      </a>
                    </li>
                    <li className="p-2 hidden lg:block">
                      <a
                        href="https://onboarding.artpass.id"
                        target="_blank"
                        className="border px-6 py-[10px] h-[40px] border-solid border-white !text-[16px] !font-medium hover:border-none hover:bg-[#507025] !text-white"
                      >
                        Join us now!
                      </a>
                    </li>
                    <button
                      data-collapse-toggle="divbar-sticky"
                      type="button"
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      id="menu-icon"
                      className="flex items-center w-9 h-9 min-w-[36px] justify-center text-sm text-white rounded-full lg:hidden"
                      aria-controls="navbar-sticky"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </ul>
                </div>
              </nav>
            </header>

            <div
              className={`fixed top-0 right-0 left-0 z-50 sm:w-3/4  ${
                !toggle ? "-translate-x-full" : ""
              } h-[50%] w-full max-w-[20rem]  bg-white shadow-lg transition duration-500`}
              id="menu-items"
            >
              <ul className="list-none p-6">
                <li className="py-2 px-4">
                  <a href="/" className="!font-bold !text-lg">
                    Home
                  </a>
                </li>
                <li className="py-2 px-4">
                  <a
                    href="https://onboarding.artpass.id"
                    target="_blank"
                    className="!font-bold !text-lg"
                  >
                    Join us
                  </a>
                </li>
                <li className="py-2 px-4">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      setModal(true);
                      setToggle(false);
                    }}
                    data-toggle="modal"
                    data-target="#artpass-signin"
                    className="!font-bold !text-lg"
                  >
                    Sign in
                  </a>
                </li>
                <li className="py-2 px-4">
                  <a href="/business" className="!font-bold !text-lg">
                    Business
                  </a>
                </li>
                <li className="py-2 px-4">
                  <a href="/personal" className="!font-bold !text-lg">
                    Personal
                  </a>
                </li>
              </ul>
            </div>

            {/* Hero  */}
            <section
              id="slider"
              className="bg-[#000000] p-0 odd h-full flex justify-center items-center"
            >
              <div className="swiper-container no-slider animation slider-h-100 slider-h-auto">
                <img
                  src={scanAndConnectHeader}
                  alt="Full Image"
                  className="full-image"
                  data-mask="0"
                />

                <div className="slide-content">
                  <div className="flex flex-col justify-center items-start inner">
                    <div className="items-start text-start w-full sm:w-3/4 xl:px-0 px-6">
                      <br />
                      <p
                        data-aos="zoom-in"
                        data-aos-delay="2000"
                        className="title effect-static-text !font-bold sm:whitespace-nowrap lg:!text-[68px] !text-[50px] ms:!text-[54px] pt-10 ms:pt-6 tracking-wide"
                      >
                        1-Click <br />
                        <span className="relative whitespace-nowrap">
                          Compliance
                          <div className="absolute top-[13px] lg:top-[17px] right-[-12px] !text-[17px] lg:!text-[19px]">®</div>
                        </span>
                      </p>
                      <p
                        data-aos="zoom-in"
                        data-aos-delay="2400"
                        className="description bigger !text-[25px] ms:!text-[30px] lg:!text-[40px] !font-semibold tracking-wide"
                      >
                        Digital wallet that makes <br />
                        compliant art transactions easy
                      </p>
                      <div
                        className="flex flex-col ss:flex-row md:gap-5 gap-3  mt-9"
                        data-aos="fade-up"
                        data-aos-delay="2800"
                      >
                         <a
                          href="https://apps.apple.com/be/app/artpass-id/id1497860703"
                          target="_blank"
                        >
                          <img
                            src={downloadApp}
                            alt="Logo"
                            className="logo h-[44px] sm:h-[46.03px]"
                            width="160"
                            
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.artpassid.artplus"
                          target="_blank"
                        >
                          <img
                            src={downloadApp2}
                            alt="Logo"
                            className="logo h-[44px] sm:h-[46.03px]"
                            width="160"
                          />
                        </a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Services  */}
            <section
              id="services"
              className="offers h-full relative pt-[70px] !bg-black"
            >
              <div className="2xl:mx-[200px] xl:mx-[150px] mx-auto pb-5">
                <div className="m-0 font-medium text-center px-4 xl:px-0 text-xl lg:text-2xl flex justify-center text-white md:mb-10 mb-8 ">
                  Money Laundering laws require art market participants to
                  perform Know Your Customer (KYC) checks before concluding any
                  transaction valued at €10K or more. artpass ID is the first of
                  its kind digital KYC identity trust network for AML compliant
                  art buying and selling.
                </div>
                <div className="mx-0 flex justify-center items-center md:mb-10 mb-8">
                  <div className="p-4">
                    <p className="text-white flex justify-center items-center !text-[32px] lg:!text-[54px] my-6">
                      How does it work?
                    </p>
                    <div className="relative px-16 lg:px-auto">
                      <img
                        src={videoBg}
                        alt=""
                        className="lg:w-[740px] lg:h-[420px] first-letter w-[500px] h-[300px] md:w-[650px] md:h-[350px] object-cover relative"
                      />

                      <FaPlay
                        className="w-[70px] h-[70px] hover:w-20 hover:h-20 absolute text-white bottom-[40%] right-0 left-[50%]"
                        onClick={() => setIsOpen(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-4 flex justify-center items-center flex-col md:px-4 xl:px-0 mx-0 md:mb-10 mb-8">
                  <p className="text-white !text-[32px] lg:!text-[54px]">
                    Trusted Feedback
                  </p>
                  <p className="text-xl lg:text-2xl text-white font-medium text-center my-7">
                    We work intensively in pursuit of achieving eco-friendly,
                    friction-free Anti-Money Laundering (AML) compliant art
                    transactions, while building a more sustainable culture of
                    trust and transparency. This is what moves us and we are
                    grateful for the recognition.
                  </p>
                </div>
                <div className="mx-0 mb-10 grid md:grid-cols-3 grid-cols-1  justify-between items-start text-white  gap-6 xl:gap-8 p-2 px-9 md:px-5 xl:px-0">
                  <div className="xl:p-6 p-8  bg-[#ffffff0d] text-center flex flex-col justify-center items-center rounded-[10px] transition ease-in-out delay-200 hover:-translate-y-[7.5px] duration-500">
                    <div className="h-32 w-32">
                      <img
                        alt=""
                        src={user1}
                        className="h-full w-full object-cover rounded-full"
                      />
                    </div>
                    <h5 className="text-white my-6">
                      Anne Vierstraete
                      <p className="text-sm pt-[6px]">
                        Senior Advisor, Head of Alliances & Partnerships, Art
                        Brussels & Art Antwerp
                      </p>
                    </h5>
                    <p className="pb-4 text-base">
                      “The user-friendliness of the artpass ID app to meet the
                      KYC requirements in the AML regulation, contributes to
                      strengthening trust and transparency in the art market,
                      including international art fairs themselves. This is an
                      invaluable tool for everyone’s benefit.”{" "}
                    </p>
                  </div>
                  <div className="xl:p-6 p-8  bg-[#ffffff0d] text-center flex flex-col justify-center items-center rounded-[10px] transition ease-in-out delay-200 hover:-translate-y-[7.5px] duration-500">
                    <div className="h-32 w-32">
                      <img
                        alt=""
                        src={user2}
                        className="h-full w-full object-cover rounded-full"
                      />
                    </div>
                    <h5 className="text-white my-6">
                      Deborah Gage
                      <p className="text-sm pt-[6px]">
                        Director Deborah Gage Art
                      </p>
                    </h5>
                    <p className="pb-4 text-base">
                      “I cannot express a higher recommendation for artpass ID.
                      What has really impressed me most of all is artpass ID’s
                      truly ‘hands on’ support so that we have become
                      comfortable using the app. This brilliant app will become
                      an essential tool to the art industry.”{" "}
                    </p>
                  </div>
                  <div className="xl:p-6 p-8  bg-[#ffffff0d] text-center flex flex-col justify-center items-center rounded-[10px] transition ease-in-out delay-200 hover:-translate-y-[7.5px] duration-500">
                    <div className="h-32 w-32">
                      <img
                        alt=""
                        src={user3}
                        className="h-full w-full object-cover rounded-full"
                      />
                    </div>
                    <h5 className="text-white my-6">
                      Rakhi Talwar
                      <p className="text-sm pt-[6px]">
                        Director RTalwar Compliance
                      </p>
                    </h5>
                    <p className="pb-4 text-base">
                      “artpass ID offers a sustainable, world-class compliant
                      solution in which authenticated identity credentials can
                      be shared instantly. Gone are the days when collectors
                      will have to send KYC documents to different dealers every
                      time they want to buy or sell art.”{" "}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Process */}
            <section className="offers pt-[10px] h-full flex flex-col justify-center items-center !bg-black ">
              <p className="text-white w-full flex items-center justify-center text-center !text-[32px] lg:!text-[54px] mb-12">
                Get your FREE artpass ID in minutes!
              </p>
              <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-4 lg:justify-between justify-center items-center text-center 2xl:mx-[300px] xl:mx-[250px] lg:mx-[140px]  mx-auto pb-5">
                <div className="md:px-5 flex justify-center items-center flex-col mb-7 lg:mb-0">
                  <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                    <span className="">1</span>
                  </div>
                  <h4 className="text-white font-medium my-6">Onboard</h4>
                  <p className="text-white">
                    Self-create your artpass ID securely within minutes
                  </p>
                </div>
                <div className="md:px-5 flex justify-center items-center flex-col mb-7 lg:mb-0">
                  <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                    <span className="">2</span>
                  </div>
                  <h4 className="text-white font-medium my-6">Connect</h4>
                  <p className="text-white">
                    Scan and Connect to exchange verified identity credentials
                  </p>
                </div>
                <div className="md:px-5 flex justify-center items-center flex-col mb-7 lg:mb-0  sm:col-span-2 lg:col-span-1">
                  <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                    <span className="">3</span>
                  </div>
                  <h4 className="text-white font-medium my-6">Transact</h4>
                  <p className="text-white max-w-[400px] break-words">
                    Ongoing screening ensures compliant transactions
                  </p>
                </div>
              </div>
              <button className="border px-6 py-[10px] h-11 border-solid border-white hover:border-none hover:bg-[#507025] my-14  !text-white">
                <a className="!text-white" href="https://onboarding.artpass.id">
                  Join us now
                </a>
              </button>
            </section>


            {/* section 7 */}
            <section
              id="comp"
              className="section-3 bg-black w-full flex justify-center items-center flex-col py-9"
            >
              <p className="!text-white max-w-[800px] text-center mb-[70px] !text-[32px] lg:!text-[54px] ">
                artpass ID is ISO 27001:2022 Certified and complies with AML and Data Protection Regulations.
              </p>
              <div className="justify-center items-center grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 mb-12 lg:gap-0 gap-2 lg:max-w-[800px] w-full ">
                <div className="flex justify-center items-center">
                  <img alt="" className="w-32 h-32 lg:px-4 lg:h-auto" src={eu} />
                </div>

                <div className="flex justify-center items-center">
                  <img alt="" className="w-32 h-32 lg:px-4 lg:h-auto" src={uk} />
                </div>
                <div className="flex justify-center items-center">
                  <img
                    alt=""
                    className="w-32 h-32 lg:px-4 lg:h-auto"
                    src={sha256}
                  />
                </div>
                <div className="flex justify-center items-center">
                  <img
                    alt=""
                    className="w-32 h-32 lg:px-4 lg:h-auto"
                    src={gdpr}
                  />
                </div>
                <div className="flex justify-center items-center">
                  <img
                    alt=""
                    className="w-32 h-32 lg:px-4 lg:h-auto"
                    src={ccpa}
                  />
                </div>
                <div className="flex justify-center items-center">
                  <img
                    alt=""
                    className="w-32 h-32 lg:px-4 lg:h-auto"
                    src={iso}
                  />
                </div>
              </div>
              <a
                href="/compliance"
                target="_blank"
                className="!text-white border px-6 py-[10px] h-11 border-solid border-white hover:border-none hover:bg-[#507025] my-14"
              >
                Compliance & Security info
              </a>
            </section>
            
            {/* Contact  */}
            <section id="contact" className="!bg-black  pb-9">
              <div className="flex justify-center items-center">
                <div className="grid md:grid-cols-3 grid-cols-1 container !px-7">
                  <div className="pr-11 pl-2 self-center md:col-span-2">
                    <div className="flex flex-col justify-center items-start mb-16 text-white">
                      <p className="text-white !text-[32px] lg:!text-[54px]">
                        Get in Touch
                      </p>
                      <p>
                        We will respond to your message as soon as possible.
                      </p>
                    </div>
                    <div className="row form-group-margin">
                      <form onSubmit={handleSubmit}>
                        <div className="row form-group-margin">
                          <div className="grid md:grid-cols-2 grid-cols-1 w-full">
                            <div className="p-2 flex flex-col">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="bg-[#ffffff0d] px-3 py-[6px] h-[50px] text-[#ffffff]"
                                placeholder="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                component="div"
                                name="name"
                                className="text-red-500 text-sm"
                              />
                            </div>
                            <div className="p-2">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="bg-[#ffffff0d] px-3 py-[6px] h-[50px] text-[#ffffff]"
                                placeholder="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                component="div"
                                name="email"
                                className="text-red-500 text-sm"
                              />
                            </div>
                          </div>
                          <div className="p-2 ">
                            <textarea
                              type="message"
                              name="message"
                              id="message"
                              className="!bg-[#ffffff0d] px-3 py-[6px] min-h-[150px] h-full !text-[#ffffff]"
                              placeholder="Message"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="message"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="col-12 col-12 m-0 p-2 input-group">
                            <span className="form-alert"></span>
                          </div>
                          <div className="p-2">
                            <button
                              className="py-3 px-6 h-12 border-solid border border-white !text-white hover:border-none hover:bg-[#507025]"
                              type="submit"
                            >
                              SEND
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="pt-2 px-[6px] text-white">
                    <h4 className="text-white">artpass ID Ltd.</h4>
                    <p className="pb-6">
                      artpass ID is a digital identity trust ('DIT’) network
                      infrastructure for buying and selling art.
                    </p>
                    <p className="pb-6">
                      Our goal is to achieve friction-free Anti-Money Laundering
                      (AML) compliant art transactions, while building a culture
                      of trust and transparency.
                    </p>
                    <p className="pb-6">+44 77 2740 7318</p>
                    <p className="pb-6">hello@artpass.id</p>
                    <p className="pb-6">
                      71-75 Shelton Street
                      <br />
                      London WC2H 9JQ
                      <br />
                      United Kingdom
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <footer>
              <section id="copyright" className="p-4 !bg-black">
                <div className="flex justify-center items-center">
                  <div className="container">
                    <div className="p-2 text-center text-white text-base">
                      <p>
                        <a
                          href={baseUrl + "/terms/"}
                          target="_blank"
                          className="!text-white font-light"
                        >
                          Terms & Conditions{" "}
                        </a>
                        <a
                          href={baseUrl + "/privacy_policy.html"}
                          target="_blank"
                          className="!text-white font-light"
                        >
                          {" "}
                          | Privacy Policy{" "}
                        </a>
                        <a
                          href={baseUrl + "/cookie_policy.html"}
                          target="_blank"
                          className="!text-white font-light"
                        >
                          {" "}
                          | Cookie Policy{" "}
                        </a>
                        <a
                          href={baseUrl + "/disclaimer.html"}
                          target="_blank"
                          className="!text-white font-light"
                        >
                          {" "}
                          | Disclaimer{" "}
                        </a>
                        <a
                          href={baseUrl + "/#artpass-signin"}
                          target="_blank"
                          className="!text-white font-light"
                        >
                          {" "}
                          | Sign in{" "}
                        </a>
                        <a
                          href="#artpass-signin"
                          data-dismiss="modal"
                          data-toggle="modal"
                          className="!text-white font-light"
                        >
                          | Sign in
                        </a>
                        <a
                          href="https://onboarding.artpass.id/#/"
                          target="_blank"
                          className="!text-white font-light"
                        >
                          {" "}
                          | Join us{" "}
                        </a>
                      </p>
                    </div>
                    <div className="text-center text-white text-xs">
                      artpass ID Ltd. is registered as a Data Processor with the
                      UK Information Commissioner’s Office under number
                      ZA633666. Our data protection officer can be contacted by
                      email at dpo@artpass.id. © 2021 artpass ID Ltd. All rights
                      reserved. artpass ID, 1-Click KYC and 1-Click Compliance
                      are registered trademarks by artpass ID Ltd. Company
                      number: 12402898
                    </div>
                  </div>
                </div>
              </section>
            </footer>

            {/* Scroll [to top]  */}
            <div id="scroll-to-top" className="scroll-to-top">
              <a href="#header" className="smooth-anchor">
                <BsArrowUp />
                {/* <i className="fas fa-arrow-up"></i> */}
              </a>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
