import { SwapOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import DirectDealroom from './DealroomSellerBuyer';

function DealroomsCreate() {

  const [isDirectDealroom, setDirectDealroom] = useState(false);
  const [isAMPDealroom, setAMPDealroom] = useState(false);

  return ( <>
    { !isDirectDealroom && !isAMPDealroom && <Row gutter={[16, 16]} align="middle">
      <Col span={24}>
        <Card bordered={false} className="bg-light">
          <Row align="middle">
            <Col flex="auto">
              <Typography.Text>
                SELLER
                <SwapOutlined style={{ margin: "0 10px" }} />
                BUYER
              </Typography.Text>
            </Col>
            <Col flex="none">
              <Button type="primary" onClick={ () => { setDirectDealroom(true); } }>Create</Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card bordered={false} className="bg-light">
          <Row align="middle">
            <Col flex="auto">
              <Typography.Text>
                SELLER
                <SwapOutlined style={{ margin: "0 10px" }} />
                AMP
                <SwapOutlined style={{ margin: "0 10px" }} />
                BUYER
              </Typography.Text>
            </Col>
            <Col flex="none">
              <Button type="primary">Create</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row> }
    { isDirectDealroom && <><DirectDealroom onBack={ () => { setDirectDealroom(false); } } /></> }
    { isAMPDealroom && <>AMPDEALROOM CREATE</> }
    </>
  );
}

export default DealroomsCreate;
