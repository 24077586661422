import { Divider, Button, Card, Col, Empty, Image, Row } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../services/services.profile";
function DealroomsPast() {
  const [archived, setArchived] = useState([]);
  const [dealrooms, setDealrooms] = useState({});
  useEffect(() => {
    const dealrooms = async () => {
      const res = await axios.get(baseUrl+"/api/kycaml/archived");
      setArchived(res.data);
      setDealrooms(res.data.total);
    };
    dealrooms();
  }, []);

  return (
    <Row>
      <Col span={24}>
        {dealrooms?.length > 0 ? (
          <>
            {dealrooms?.map((item) => (
              <>
                <Row
                  style={{ alignItems: "center" }}
                  align="top"
                  className="bg-light"
                >
                  <Col span={4}>
                    <Image
                      src={baseUrl+"/" + item.attachments.iPath}
                      width={"100%"}
                      style={{
                        minHeight: "130px",
                        maxHeight: "150px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                  <Col span={4}> </Col>
                  <Col span={5}>
                    <Button
                      onClick={() => {
                        window
                          .open(
                            baseUrl+"/transac-amp-rp/" +
                              item.transacId +
                              "/seller",
                            "_blank"
                          )
                          .focus();
                      }}
                      type="primary"
                    >
                      Seller Transaction Report
                    </Button>
                  </Col>
                  <Col span={5}>
                    <Button
                      onClick={() => {
                        window
                          .open(
                            baseUrl+"/transac-amp-rp/" +
                              item.transacId +
                              "/buyer",
                            "_blank"
                          )
                          .focus();
                      }}
                      type="primary"
                    >
                      Buyer Transaction Report
                    </Button>
                  </Col>
                </Row>
                <Divider />
              </>
            ))}
          </>
        ) : (
          <Card>
            <Empty />
            {/* <Result /> */}
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default DealroomsPast;
