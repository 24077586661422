import { Button, Card, Col, Image, Row, Space } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { baseUrl } from '../services/services.profile';
import { AiOutlineClockCircle } from "react-icons/ai";
import { message } from "antd";

function ScreeningResults() {

    const [screens, setScreens] = useState([]);
    const search = async (data) => {
        var res = await axios.get(baseUrl + "/api/aml/find", data);
        console.log(        res.data.data
            );
        setScreens(res.data.data);
    }
    const deleteScreen  = async (screen) => {
        var res = await axios.post(baseUrl + "/api/aml/delete_screening", screen);
        console.log(res.data.data);
        message.success("Successfully deleted");
        search();
    }

    useEffect(() => {
        search();
    }, []);

    return (
        <>
    <Space direction="vertical" size={"large"}>
        {screens.map((screen, i) => {
            if(!screen.inspection_report) { return; }
            return <Row gutter={[16, 16]} align="middle" justify="center" key={i}>
            {/* <Col xs={24} md={4} flex="100px">
                <Image
                    src={baseUrl + "/images/users/pdf-icon.png"}
                    preview={false}
                />
            </Col> */}
            <Col flex={"auto"}>
                <Card bordered={false} className="bg-light">
                    <Row align="middle" gutter={[16, 8]}>
                        <Col xs={24} lg={14}>
                            {screen.amlReport.info.firstName} {screen.amlReport.info.lastName}
                            <br/>
                            <button
                                className="px-2 h-9 sm:h-7 border whitespace-nowrap border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger  hover:text-white hover:bg-danger" style={{ marginTop: "4px" }}
                                onClick={() => {
                                deleteScreen(screen);
                                }}
                            >
                                Remove
                            </button>
                        </Col>
                        {/* <Col flex={"auto"}>
                            ({screen.amlReport.info.nationality})
                        </Col> */}
                        <Col flex={"auto"}>
                            <Row align="middle">
                                <AiOutlineClockCircle size={16} />
                                &nbsp;<small>Year of Birth: {screen.amlReport.info.dob.split("-")[0]}</small>
                            </Row>
                        </Col>
                        <Col flex={"none"}>
                            <Button
                                onClick={() => {
                                    window
                                        .open(baseUrl +
                                            "/" + screen?.inspection_report,
                                            "_blank"
                                        )
                                        .focus();
                                }}
                                type="primary"
                            >
                                AML Inspection Report
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        })}
        
    </Space>
        </>);
}

export default ScreeningResults;