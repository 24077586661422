import React from "react";
import { Page, Text, Document, StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import moment from "moment";
import Regular from "../font/Poppins-Regular-400.ttf";
import Bold from "../font/Poppins-Bold-700.ttf";
import SemiBold from "../font/Poppins-SemiBold-600.ttf";
import Medium from "../font/Poppins-Medium-500.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: Regular,
    },
    {
      src: Bold,
      fontWeight: "bold",
    },
    {
      src: SemiBold,
      fontWeight: "semibold",
    },
    {
      src: Medium,
      fontWeight: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingLeft: 65,
    paddingRight: 65,
    paddingHorizontal: 65,
    fontWeight: 100,
  },
  //   title: {
  //     fontSize: 24,
  //     textAlign: "center",
  //     // fontFamily: "Nunito Sans",
  //   },
  text: {
    // margin: 12,
    fontSize: 11,
    // textAlign: "justify",
    // fontFamily: "Nunito Sans",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Poppins",
  },
  page: {
    color: "#383838",
    lineHeight: 1.3,
    // fontWeight: 100,
    // fontFamily:'Nunito Sans',
    letterSpacing: "0.5px",
  },
  text1: {
    fontSize: 11,
    // fontWeight: "bold",
    bottom: 0,
  },
  mb: {
    // paddingTop: 10,
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
  },
  p: {
    fontSize: 11,
    display: "flex",
    gap: 1,
    paddingTop: "3px",
    bottom: 0,
    textAlign: "justify",
  },
  text13: {
    fontSize: 11,
    textAlign: "justify",
  },
  semibold: {
    // fontWeight: "semibold",
  },
  semiboldfont: {
    fontWeight: "semibold",
    fontFamily: "Poppins",
  },
  boldfont: {
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  userdetail: {
    fontSize: 11,
    // paddingTop: 1,
    // fontWeight: "bold",
  },
  userDetailTitle: {
    fontSize: 11,
    // paddingTop: 2,
  },
  last: {
    fontSize: 11,
    paddingBottom: 15,
  },
  doc: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
  },
  pdfinfo: {
    fontSize: 11,
  },
  number: {
    // marginHorizontal: 2,
    // marginVertical:5
    fontSize: 12,
    display: "flex",
    alignItems: "flex-start",
    gap: 4,
    justifyContent: "flex-start",
  },
});

const PDFFile = ({
  data,
  userinfo,
  connectCountryCode,
  connectionDetail,
  docList,
  filename,
}) => {
  return (
    <Document>
      <Page size="A4" style={{ ...styles.body, ...styles.page }}>
        <Text style={styles.header}>
          Risk Assessment Report
          {"\n"}
        </Text>

        {/* Risk assessment on:  */}
        <Text style={styles.last}>
          <Text style={{ ...styles.boldfont, ...styles.userdetail }}>
            {userinfo && userinfo?.company?.status === "passed"
              ? userinfo?.company?.name
              : userinfo?.fname + " " + userinfo?.lname}
            {"\n"}
          </Text>

          <Text style={styles.userdetail}>
            {userinfo && userinfo?.company?.status === "passed"
              ? userinfo?.company?.addr?.street_address
              : userinfo && userinfo?.location?.street}
            {"\n"}
          </Text>

          <Text style={styles.userdetail}>
            {userinfo && userinfo?.company?.status === "passed"
              ? userinfo?.company?.addr?.postal_code +
                " " +
                userinfo?.company?.addr?.locality +
                " " +
                userinfo?.company?.addr?.country
              : userinfo?.location?.postCode +
                " " +
                userinfo?.location?.town +
                " " +
                userinfo?.location?.country}
            {"\n"}
          </Text>
        </Text>

        {/* Contact detail  */}
        <Text style={styles.last}>
          <Text style={{ ...styles.semiboldfont, ...styles.userDetailTitle }}>
            Contact: {"\n"}
          </Text>
          {userinfo && userinfo?.company?.status === "passed" && (
            <Text style={styles.userdetail}>
              {userinfo?.fname + " " + userinfo?.lname}
              {"\n"}
            </Text>
          )}
          <Text style={styles.userdetail}>
            {connectCountryCode && "+" + connectCountryCode + "   "}
            {userinfo?.phone}
            {"\n"}
          </Text>
          <Text style={styles.userdetail}>
            {userinfo?.email}
            {"\n"}
          </Text>
        </Text>

        <Text style={{ ...styles.last, ...styles.semiboldfont }}>
          Last updated on:{" "}
          {moment
            .unix(connectionDetail?.updated_risk_time / 1000)
            .format("DD MMM YYYY")}
          {"\n"}
        </Text>

        {/* comment list detail  */}
        {data.map((item, index) => (
          <Text style={styles.mb} key={index}>
            <Text style={styles.text1}>
              {data?.length === index + 1 ? "Added" : "Updated"} on:{" "}
              {moment.unix(item?.createdAt / 1000).format("DD MMM YYYY")}
              {"\n"}
            </Text>

            <Text style={styles.p}>
              PEP:{" "}
              {item?.risk_types[0]?.pep_prev !== "" &&
                item?.risk_types[0]?.pep_present !==
                  item?.risk_types[0]?.pep_prev && (
                  <Text style={styles.text13}>
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[0]?.pep_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[0]?.pep_prev === "Medium risk" &&
                      item?.risk_types[0]?.pep_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[0]?.pep_prev === "High risk" &&
                          item?.risk_types[0]?.pep_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[0]?.pep_prev === "High risk" &&
                          item?.risk_types[0]?.pep_present === "Medium risk"
                        ? "decreased"
                        : item?.risk_types[0]?.pep_present === ""
                        ? "No PEP"
                        : " increased"}{" "}
                      {item?.risk_types[0]?.pep_present !== "" && "to"}
                    </Text>
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[0]?.pep_present === "" &&
                item?.risk_types[0]?.pep_prev === ""
                  ? "No PEP"
                  : item?.risk_types[0]?.pep_present}{" "}
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.p}>
              Adverse Media:
              {item?.risk_types[1]?.adversemedia_prev !== "" &&
                item?.risk_types[1]?.adversemedia_present !==
                  item?.risk_types[1]?.adversemedia_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[1]?.adversemedia_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[1]?.adversemedia_prev ===
                        "Medium risk" &&
                      item?.risk_types[1]?.adversemedia_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[1]?.adversemedia_prev ===
                            "High risk" &&
                          item?.risk_types[1]?.adversemedia_present ===
                            "Low risk"
                        ? "decreased"
                        : item?.risk_types[1]?.adversemedia_prev ===
                            "High risk" &&
                          item?.risk_types[1]?.adversemedia_present ===
                            "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[1]?.adversemedia_present}{" "}
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.p}>
              Activity/Funds:
              {item?.risk_types[2]?.activityfunds_prev !== "" &&
                item?.risk_types[2]?.activityfunds_present !==
                  item?.risk_types[2]?.activityfunds_prev && (
                  <Text style={styles.text13}>
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[2]?.activityfunds_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[2]?.activityfunds_prev ===
                        "Medium risk" &&
                      item?.risk_types[2]?.activityfunds_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[2]?.activityfunds_prev ===
                            "High risk" &&
                          item?.risk_types[2]?.activityfunds_present ===
                            "Low risk"
                        ? "decreased"
                        : item?.risk_types[2]?.activityfunds_prev ===
                            "High risk" &&
                          item?.risk_types[2]?.activityfunds_present ===
                            "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[2]?.activityfunds_present}{" "}
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.p}>
              Region:
              {item?.risk_types[3]?.region_prev !== "" &&
                item?.risk_types[3]?.region_present !==
                  item?.risk_types[3]?.region_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[3]?.region_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[3]?.region_prev === "Medium risk" &&
                      item?.risk_types[3]?.region_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[3]?.region_prev === "High risk" &&
                          item?.risk_types[3]?.region_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[3]?.region_prev === "High risk" &&
                          item?.risk_types[3]?.region_present === "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[3]?.region_present}{" "}
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.p}>
            Documents:
              {item?.risk_types[5]?.document_prev !== "" &&
                item?.risk_types[5]?.document_present !==
                  item?.risk_types[5]?.document_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[5]?.document_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[5]?.document_prev === "Medium risk" &&
                      item?.risk_types[5]?.document_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[5]?.document_prev === "High risk" &&
                          item?.risk_types[5]?.document_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[5]?.document_prev === "High risk" &&
                          item?.risk_types[5]?.document_present === "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[5]?.document_present}{" "}
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.p}>
              Overall risk:
              {item?.risk_types[4]?.overall_risk_prev !== "" &&
                item?.risk_types[4]?.overall_risk_present !==
                  item?.risk_types[4]?.overall_risk_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[4]?.overall_risk_prev} risk
                    </Text>
                    <Text>
                      {item?.risk_types[4]?.overall_risk_prev === "Medium" &&
                      item?.risk_types[4]?.overall_risk_present === "Low"
                        ? " decreased"
                        : item?.risk_types[4]?.overall_risk_prev === "High" &&
                          item?.risk_types[4]?.overall_risk_present === "Low"
                        ? " decreased"
                        : item?.risk_types[4]?.overall_risk_prev === "High" &&
                          item?.risk_types[4]?.overall_risk_present === "Medium"
                        ? " decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[4]?.overall_risk_present} risk
              </Text>
              {"\n"}
            </Text>

            <Text style={styles.text1}>Comments: {"\n"}</Text>
            <Text style={styles.text} key={index}>
              {item.description}
              {"\n"}
            </Text>
          </Text>
        ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default PDFFile;
